<template>
  <HeaderTopBarVue/>
  <AsideMenuVue/>
  <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--AllProduit--->
                <div class="row">
                            <!-- Column -->
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="material-card card">
                                        <div class="card-body">
                                            <h2 style="margin-top:0px;">Les produits à livrer <span class="badge badge-inverse badge-pill ml-auto mr-3 font-medium px-2 py-1">{{products.length}}</span></h2>
                                        <!-- <hr class="mt-3 mb-4"> -->
                                            <div class="table-responsive">
                                                <table class="table product-overview" id="zero_config" v-if="products.length>0">
                                                    <thead> 
                                                        <tr>
                                                            <th>Photo</th>
                                                            <th>Product</th>
                                                            <th>Quantité</th>
                                                            <th>Trace</th>
                                                            <th>Personne</th>
                                                            <th>Adresse</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                            <tr v-for="product in products" :key="product.commandes_id">
                                                                <td class="col-lg-3 col-md-3 col-sm-6 colonne-etroite"><img :src="`http://apiy.htvann.com/${product.cover_img}`" class="img-responsive img-fluid img-thumbnail"></td>
                                                                <td>{{product.sounom}}</td>
                                                                <td>{{product.quantite_element}}</td>
                                                                <td class="font-500">{{product.tras}}</td>
                                                                <td>{{product.nom}}</td>
                                                                <td>{{product.rue}}, {{product.zone}}</td>
                                                            
                                                                <td>
                                                                    <!-- <router-link :to="{ name: 'produit-details', params: { id_produit: produit.id } }" class="text-inverse pr-2" data-toggle="tooltip" title="Details"><i class="fa fa-eye"></i></router-link> -->
                                                                    <!-- <router-link :to="{ name: 'commande-details', params: { id_order: product.commande_id } }" @click="redirectTo(`/commande-details/${product.commande_id}`);event.preventDefault();" class="text-inverse pr-2" data-toggle="tooltip" title="Details"><i class="fa fa-eye"></i></router-link> -->
                                                                    <a href="#" class="text-inverse" type="button" data-toggle="modal" :data-target="'#myModal'+product.commande_id" title="Delete"><i class="fa fa-shipping-fast"></i></a>
                                                                </td>
                                                                <!-- The Modal -->
                                                                <div class="modal" :id="'myModal'+product.commande_id">
                                                                <div class="modal-dialog">
                                                                    <div class="modal-content">

                                                                    <!-- Modal Header -->
                                                                    <div class="modal-header">
                                                                        <h4 class="modal-title mx-auto">Commande #{{product.commande_id}}</h4>
                                                                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                                                                    </div>

                                                                    <!-- Modal body -->
                                                                    <div class="modal-body">
                                                                        <p class="text-center font-weight-bold text-decoration-underline">{{product.rue}}, {{product.zone}}</p>
                                                                        <form>
                                                                            <div class="form-group">
                                                                                <label for="tras">Entrer le code:</label>
                                                                                <input type="text" class="form-control" id="tras" v-model="code_tracking" :autofocus="true">
                                                                            </div>
                                                                            <p class="text-center">
                                                                                <button type="button" @click="expedierCommande" class="btn btn-primary">Expedier</button>
                                                                            </p>
                                                                        </form>
                                                                    </div>

                                                                    <!-- Modal footer -->
                                                                    <div class="modal-footer">
                                                                        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                                                                    </div>

                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </tr>                                  
                                                        
                                                    </tbody>
                                                </table>
                                                <p v-else>Aucune Commande à livrer.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <!-- Column -->
                        </div>

                <!--End AllProduit--->
                            
                        
                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
</template>

<script>
import axios from 'axios';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:"CommandeLivree",
components: {
    HeaderTopBarVue,
    AsideMenuVue
  },
  data() {
    return {
      products: []
    };
  },
  async created(){
       axios.get(`${base_url}/elements_status?status=2`)
            .then((response)=>{
                // console.log(response.data);
                this.products=response.data;
            })
            .catch((error)=>{
                alert('Une erreur est survenue!');
                console.error(error);
            })
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        },1);
    }
  }
}
</script>

<style>

</style>