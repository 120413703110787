<template>
<main-vue>
<!--Login--->
                        <div class="container mt-3">
                            <div class="row">
                                <div class="col-md-6 mx-auto">
                                    <div class="card card-body">
                                        <span id="imgo">
                                        <img src="../../public/frontend/images/logo/logo.png" class="img-responsive logo" alt="Image">            
                                        </span><br>       
                                        <h3 class="text-center text-primary">Login</h3>
                                        <form @submit.prevent="loginuser">
                                            <div class="form-group">
                                                <label for="email">Email</label>
                                                <input type="text" class="form-control" v-model="emailzode" id="email" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="password">Password</label>
                                                <input type="password" class="form-control" v-model="passzode" id="pass" required>
                                            </div>
                                            <button type="submit" class="btn btn-primary">Submit</button>                
                                        </form>
                                    </div>
                                </div>    
                            </div>
                            </div>
                <!--End Login--->    
</main-vue>
    
</template>

<script>
import axios from 'axios';
import MainVue from '@/components/Main.vue';
import {systemInfo} from '../services/systemInfo'
import CryptoJS from 'crypto-js'
const base_url = systemInfo.base_url;
// Clé de chiffrement
const encryptionKey = "JeSuisLePlusGrandMiracleDuMonde";
export default {
name:'Login',
components: {
MainVue
},
data() {
    return {
      emailzode: '',
      passzode: ''
    }
  },
  methods:{
    async loginuser() { 
        // console.log(this.emailzode); false;  
            axios.post(`${base_url}/auth/login`, {
                mail: this.emailzode,
                password: this.passzode
            })
            .then((user)=>{
                // console.log(user.data.utilisateur);
                // console.log(user.data.token);
                const dataUser = {
                    id:user.data.utilisateur.id,
                    nom:user.data.utilisateur.nom,
                    prenom:user.data.utilisateur.prenom,
                    telephone:user.data.utilisateur.telephone,
                    mail:user.data.utilisateur.mail,
                    role:user.data.utilisateur.role,
                    token:user.data.token
                }
                console.log(dataUser);

                // localStorage.setItem('token', user.data.token);
                // La connexion a réussi, stocker les données de l'utilisateur dans Vuex ou localStorage
                
                this.$store.commit('setUser', dataUser);
                this.$store.commit('setUserCryp', this.encryptObject(dataUser,encryptionKey));
                // Stocker les données de l'utilisateur dans Vuex
                // this.$store.commit('setUser', dataUser);
                // Rediriger l'utilisateur vers le dashboard
                if(user.data.token){
                    this.$router.push('/dashboard'); 
                    setTimeout(function() {
                        location.reload();
                    },1);
                }
                // Rediriger l'utilisateur vers le dashboard
                // this.$router.push({ name: 'Dashboard', params: { user: response.data.user } });
                // this.$router.push({name: 'Dashboard',params:{user:user}});
            })
            .catch((error)=>{
                alert('Une erreur est survenue lors de la connexion');
                console.error(error);
            })          
       
    },
    encryptObject(obj, encryptionKey) {
        const serializedObj = JSON.stringify(obj);
        const encryptedData = CryptoJS.AES.encrypt(serializedObj, encryptionKey).toString();
        console.log(encryptedData);
        return encryptedData;
    },
    decryptObject(encryptedData, encryptionKey) {
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, encryptionKey).toString(CryptoJS.enc.Utf8);
        const deserializedObj = JSON.parse(decryptedData);
        return deserializedObj;
    }
  }
}
</script>

<style lang="scss">
#imgo{ 
    .logo{
        width: 180px;
        text-align: center;
    }
    text-align: center;
}

</style>