<template>
  <HeaderTopBarVue/>
  <AsideMenuVue/>
  <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--AllProduit--->
                <div class="row">
                            <!-- Column -->
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="material-card card">
                                        <div class="card-body">
                                            <h2 style="margin-top:0px;">Liste des décaissements <span class="badge badge-inverse badge-pill ml-auto mr-3 font-medium px-2 py-1">{{decaissementsFilter.length}}</span></h2>
                                            <div>
                                                <select v-model="statusFiltre" class="ml-2 form-control col-md-2">
                                                    <option value="all">Tous</option>
                                                    <option value="0">En Attente</option>
                                                    <option value="1">Valider</option>
                                                    <option value="2">Annuler</option>
                                                </select>
                                            </div>
                                            <br>
                                        <!-- <hr class="mt-3 mb-4"> -->
                                            <div class="table-responsive">
                                                <table class="table product-overview" id="zero_config" v-if="decaissementsFilter.length>0">
                                                    <thead> 
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Boutique</th>
                                                            <th>Montant</th>
                                                            <th>Methode</th>
                                                            <th>numero</th>
                                                            <th>Utilisateur</th>
                                                            <th>Date</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                            <tr v-for="decaissement in decaissementsFilter" :key="decaissement.id">
                                                                <td>#{{decaissement.code_decaissement}}</td>
                                                                <td> {{decaissement.boutique_name}}</td>
                                                                <td>{{decaissement.montant}} HTG</td>
                                                                <td>{{decaissement.methode}}</td>
                                                                <td>{{decaissement.numero}}</td>
                                                                <td>{{decaissement.sexe_user === 1 ? 'M. ' : 'Mme ' }}{{decaissement.nom_user}} {{decaissement.prenom_user}}</td>
                                                                <td>{{formatDate(decaissement.date_creation)}}</td>
                                                                <td>
                                                                    <a href="#" class="text-inverse pr-2" data-toggle="modal" @click="getMontantDispo(decaissement.code_boutique)" :data-target="'#valider'+decaissement.code_decaissement" title="Valider" v-if="decaissement.statut===0"><i class="fa fa-check"></i></a>
                                                                    <a href="#" class="text-inverse" data-toggle="modal" :data-target="'#cancel'+decaissement.code_decaissement" title="Annuler" v-if="decaissement.statut===1 || decaissement.statut===0"><i class="fa fa-times"></i></a>
                                                                </td>
                                                                <!--Debut Modal Valider-->
                                                                <div class="modal fade" :id="'valider'+decaissement.code_decaissement" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                    <div class="modal-dialog" role="document">
                                                                        <div class="modal-content">
                                                                        <div class="modal-header">
                                                                            <h5 class="modal-title" id="exampleModalLabel">Validation décaissement</h5>
                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                        </div>
                                                                        <div class="modal-body">
                                                                                    <div class="row">
                                                                                        <div class="col-md-12 font-weight-bold">
                                                                                            <p class="float-left">Demande : {{decaissement.montant}} HTG</p> <p class="float-right">Caisse: {{montant_dispo}} HTG</p> <br>
                                                                                        </div>

                                                                                    </div>
                                                                            <form method="POST" @submit.prevent="validerDecaissement">
                                                                                <div class="col-sm-12">
                                                                                    <label for="pname">Référence</label>
						                                                            <div class="row">
                                                                                        <div class="col-sm-6 form-group">
                                                                                            <input placeholder="Numero de transaction" ref="reference" class="form-control">
                                                                                        </div>
                                                                                        <div class="col-sm-6 form">
                                                                                            <button type="button" class="btn btn-primary">Valider</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                        <div class="modal-footer">
                                                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                                            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Fin Modal Valider-->
                                                                <!--Debut Modal Annuler-->
                                                                <div class="modal fade" :id="'cancel'+decaissement.code_decaissement" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                    <div class="modal-dialog" role="document">
                                                                        <div class="modal-content">
                                                                        <div class="modal-header">
                                                                            <h5 class="modal-title" id="exampleModalLabel">Annulation décaissement</h5>
                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                        </div>
                                                                        <div class="modal-body">
                                                                            <form method="POST" @submit.prevent="annulerDecaissement">
                                                                                <div class="col-sm-12">
                                                                                    <label for="pname">Commentaire</label>
						                                                            <div class="row">
                                                                                        <div class="col-sm-9 form-group">
                                                                                            <textarea rows="4" class="form-control">Commentaire</textarea>
                                                                                        </div>
                                                                                        <div class="col-sm-3 form">
                                                                                            <button type="button" class="btn btn-primary">Annuler</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                        <div class="modal-footer">
                                                                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                                                                            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Debut Modal Annuler-->
                                                            </tr>                                  
                                                        
                                                    </tbody>
                                                </table>
                                                <p v-else>Aucun décaissement!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <!-- Column -->
                        </div>

                <!--End AllProduit--->
                            
                        
                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
</template>

<script>
import axios from 'axios';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'jquery/dist/jquery.min.js';
// import 'popper.js/dist/popper.min.js';
// import 'bootstrap/dist/js/bootstrap.min.js';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:"AllDecaissement",
components: {
    HeaderTopBarVue,
    AsideMenuVue
  },
  data() {
    return {
      decaissements: [],
      statusFiltre:'all',
      montant_dispo:0
    };
  },
  async created(){
       axios.get(`${base_url}/decaissements`)
            .then((response)=>{
                console.log(response.data);
                this.decaissements=response.data;
            })
            .catch((error)=>{
                alert('Une erreur est survenue!');
                console.error(error);
            })
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    getMontantDispo(code){
        axios.get(`${base_url}/transactions_success`)
            .then((response)=>{
                if(response.data){
                    let filterTri=response.data.filter((element)=>element.code_boutique===code && element.status==="successful");
                    const montant_achat = filterTri.filter((element)=>element.typ==='Achat').reduce((total,element)=>total+element.montant,0);
                    const montant_decaisser = filterTri.filter((element)=>element.typ!=='Achat').reduce((total,element)=>total+element.montant,0);
                    const vente = filterTri.reduce((total,element)=>total+element.prixproduitvendu,0);
                    const comHtv = vente * 0.1;
                    this.montant_dispo = this.couperNombreDeuxDecimales((vente-comHtv)-montant_decaisser);
                    // this.montant_dispo = (montant_achat - montant_decaisser);
                    console.log(response.data);
                }
            })
            .catch((error)=>{
                alert('Une erreur est survenue!');
                console.error(error);
            })       
    },
couperNombreDeuxDecimales(nombre) {
        // Vérifier si le nombre est fini et non NaN
        if (!Number.isFinite(nombre)) {
            throw new Error("Le nombre fourni n'est pas valide.");
        }

        // Vérifier si le nombre est décimal
        if (!Number.isInteger(nombre)) {
            // Utilise toFixed pour obtenir le nombre avec deux décimales et le transformer en chaîne
            const nombreAvecDeuxDecimales = nombre.toFixed(2);

            // Utilise parseFloat pour reconvertir la chaîne en nombre avec deux décimales
            return parseFloat(nombreAvecDeuxDecimales);
        }

        // Si le nombre est un nombre entier, pas besoin de couper les décimales, retourner simplement le nombre
        return nombre;
}
  },
    computed: {
    decaissementsFilter() {
      if (this.statusFiltre === 'all') {
        return this.decaissements;
      } else {
        const statusFiltre = parseInt(this.statusFiltre,10);
        return this.decaissements.filter(element => element.statut === statusFiltre);
      }
    }
  }
}
</script>

<style>

</style>