<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--Details Commande--->
                    
                    <div class="row">
                    <div class="col-md-12">
                        <div class="material-card card">
                            <div class="card-body">
                                <h5 class="card-title">Détails de la commande</h5>
                                <div class="table-responsive">
                                    <table class="table table-bordered" v-if="products.length>0">
                                        <thead>
                                            <tr>
                                                <th>Photo</th>
                                                <th>Product</th>
                                                <th>Quantité</th>
                                                <th>Co&ucirc;t</th>
                                                <th>Livraison</th>
                                                <th>Montant</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="product in products" :key="product.commandes_id">
                                                <td class="col-lg-3 col-md-3 col-sm-6 colonne-etroite"><img :src="`http://apiy.htvann.com/${product.cover_img}`" class="img-responsive img-fluid img-thumbnail"></td>
                                                <td>{{product.sounom}}</td>
                                                <td>{{product.quantite_element}}</td>
                                                <td class="font-500">{{product.prixtotal}}</td>
                                                <td class="font-500">{{product.prixlivraison}}</td>
                                                <td class="font-500">{{product.G_total}}</td>
                                            </tr>
                                            <tr>
                                                <td colspan="5" class="font-500" align="right">Total</td>
                                                <td class="font-500">{{total}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p v-else>Aucun produit pour cette commande.</p>
                                </div>
                                <!-- <hr>
                                <h5 class="card-title">Choose payment Option</h5>
                                <ul class="nav nav-tabs" role="tablist">
                                    <li role="presentation" class="nav-item">
                                        <a href="#iprofile" class="nav-link active" aria-controls="home" role="tab" data-toggle="tab" aria-expanded="true">
                                        <span class="visible-xs"><i class="ti-home"></i></span><span class="hidden-xs"> Debit Card</span>
                                    </a>
                                    </li>
                                    <li role="presentation" class="nav-item">
                                        <a href="#ihome" class="nav-link" aria-controls="profile" role="tab" data-toggle="tab" aria-expanded="false">
                                        <span class="visible-xs"><i class="ti-user"></i></span> 
                                        <span class="hidden-xs">Paypal</span>
                                    </a>
                                    </li>
                                </ul>
                                <div class="tab-content">
                                    <div role="tabpanel" class="tab-pane" id="ihome">
                                        <br/> You can pay your money through paypal, for more info <a href="">click here</a>
                                        <br>
                                        <br>
                                        <button class="btn btn-info"><i class="fab fa-cc-paypal"></i> Pay with Paypal</button>
                                    </div>
                                    <div role="tabpanel" class="tab-pane active" id="iprofile">
                                        <div class="row">
                                            <div class="col-md-7">
                                                <form>
                                                    <div class="form-group input-group mt-5">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i class="fab fa-cc-visa"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control" placeholder="Card Number" aria-label="Amount (to the nearest dollar)">
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xs-7 col-md-7">
                                                            <div class="form-group">
                                                                <label>EXPIRATION DATE</label>
                                                                <input type="text" class="form-control" name="Expiry" placeholder="MM / YY" required=""> </div>
                                                        </div>
                                                        <div class="col-xs-5 col-md-5 pull-right">
                                                            <div class="form-group">
                                                                <label>CV CODE</label>
                                                                <input type="text" class="form-control" name="CVC" placeholder="CVC" required=""> </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label>NAME OF CARD</label>
                                                                <input type="text" class="form-control" name="nameCard" placeholder="NAME AND SURNAME"> </div>
                                                        </div>
                                                    </div>
                                                    <button class="btn btn-info">Make Payment</button>
                                                </form>
                                            </div>
                                            <div class="col-md-4 ml-auto">
                                                <h4 class="card-title mt-4">General Info</h4>
                                                <h2><i class="fab fa-cc-visa text-info"></i> <i class="fab fa-cc-mastercard text-danger"></i> <i class="fab fa-cc-discover text-success"></i> <i class="fab fa-cc-amex text-warning"></i></h2>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                                <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                    
                <!--End Details Commande--->                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
</template>

<script>
import axios from 'axios';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:"DetailsProduit",
components:{
    HeaderTopBarVue,
    AsideMenuVue
},
data() {
    return {
      products: [],
      total:0,
      execution: false,
    };
  },
  created(){
    const code_commande = this.$route.params.id_order;
    // this.info.id = code_commande;
    // this.redirectAndReload();
    console.log(code_commande);
    // console.log()
    axios.get(`${base_url}/details_commande?code_commande=${code_commande}`)
        .then(response=>{
            // console.log(response.data);
            if(response.data.length){
                this.products = response.data;
                this.total =response.data[0]?.cout ?? '';
            }else{
                this.$router.push('/dashboard');
                setTimeout(function(){
                    location.reload();
                }, 1);
            }
        })
        .catch(err=>{
            console.log(err);
        });
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    }
  },
  mounted(){
  }
}
</script>

<style>
.colonne-etroite {
  width: 100px;
}
</style>