<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                      <!--Contenu UserRegister-->
                      <div class="row">
                      <div class="col-md-12">
                      <!-- page Content -->
                          <div class="container" role="main">
                              <div class="row">
				<form action="/users/register" method="POST">
					<div class="col-sm-12">
						<div class="row">
							<div class="col-sm-4 form-group">
								<label for="usname">Nom* </label>
								<input type="text" v-model="form.nom" title="Ajouter Nom" placeholder="Nom" @keyup="validateName" required id="usname" class="form-control" autofocus="true">
                <p v-if="alertName" class="alert"> {{ alertName }} </p>
							</div>
							<div class="col-sm-4 form-group">
								<label for="usfname">Pr&eacute;nom*</label>
								<input type="text" v-mode="form.prenom" title="Ajouter Pr&eacute;nom" required placeholder="Pr&eacute;nom" name="usfname" @keyup="validateFirstName" class="form-control">
                <p v-if="alertFirstName" class="alert"> {{ alertFirstName }} </p>
							</div>
							<div class="col-sm-4 form-group">
								<label for="usmail">Email*</label>
								    <input type="text" v-model="form.email" title="Ajouter Email Valide" placeholder="zode@zode-ht.com"  @focus="validateEmail" @keyup="validateEmail" name="usmail" id="usmail" class="form-control" required>
                    <p v-if="alertMail" class="alertMail"> {{ alertMail }} </p>
							</div>
						</div>
            <div class="row">
                  <div class="col-sm-4 form-group">
                    <label for="ustelephone">T&eacute;l&eacute;phone*</label>
                      <input type="number" pattern="\d{8}" title="Format(8 Chiffres):00000000" placeholder="00000000"  name="ustelephone" id="ustelephone" class="form-control" required>
                  </div>		
                  <div class="col-sm-4 form-group">
                    <label for="usnaissance">D&acirc;te Naissance</label>
                    <input type="date" value="" name="usnaissance" id="usnaissance" class="form-control">
                  </div>	
                  <div class="col-sm-4 form-group">
                    <label for="usrole">R&ocirc;le*</label>
                    <select class="form-control custom-select" required id="usrole" name="usrole">
                        <option value="">--R&ocirc;le--</option>
                        <option value="" >role</option>
                    </select>
                  </div>	
						</div>				
						<div class="row">
							<div class="col-sm-4 form-group">
								<label for="ussexe">Sexe*</label><br/>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="M" value="1" required name="ussexe" class="custom-control-input">
									<label class="custom-control-label" for="M">M</label>
								</div>
								<div class="custom-control custom-radio custom-control-inline">
									<input type="radio" id="F" name="ussexe" value="0" class="custom-control-input">
									<label class="custom-control-label" for="F">F</label>
								</div>
							</div>	
							<div class="col-sm-4 form-group">
								<label for="usnif">NIF</label>
								<input name="usnif" type="text" pattern="\d{3}-\d{3}-\d{3}-\d" title="Veuillez entrer une chaîne au format 123-456-789-0" placeholder="123-456-789-0" id="usnif" value="" autocomplete="off" class="form-control">
							</div>	
							<div class="col-sm-4 form-group">
								<label for="uscin">CIN</label>
								<input type="text" name="uscin" id="uscin" value="" autocomplete="off" class="form-control">
							</div>							
						</div>				
                        <div class="row">
							<div class="col-sm-6 form-group">
								<label for="uspass">Password</label>
                                    <input type="password" pattern=".{4,}" title="Le mot de passe doit contenir au moins 4 caractères" name="uspass" id="password1" autocomplete="off" class="form-control" value="" required>
							</div>		
							<div class="col-sm-6 form-group">
								<label for="uscpass">Confirmer Password</label>
                                    <input value="" pattern=".{4,}" title="Le mot de passe doit contenir au moins 4 caractères" required type="password" name="uscpass" id="password2" autocomplete="off" class="form-control" >
							</div>	
						</div>				
					    <button type="submit" class="btn btn-lg btn-info btn-block">Ajouter</button>				
					</div>
				</form> 
				</div>
                          </div>
                      <!---Page Content--->
                      </div>   
                    </div>

                      <!--End Contenu UserRegister-->
                            
                        
                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->

</template>

<script>
import axios from 'axios';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:'UserRegister',
components: {
    HeaderTopBarVue,
    AsideMenuVue
  },
  data() {
    return {
        form :{
	  nom: '',
      prenom: '',
      email:''
	  },
      alertMail: ''
    }
  },
  methods: {
    validateEmail() {
    //   const regexEmail = /\S+@\S+\.\S+/;
      const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!regexEmail.test(this.form.email)) {
          this.alertMail = 'Veuillez saisir une adresse email valide!';
      } else {
        this.alertMail = '';
      }
      const regexNonVide = /\S+/;
  if (!regexNonVide.test(this.form.email)) {
    this.alertMail = 'Veuillez remplir ce champ!';
  }
    },
    validateName() {
  const regexName = /^[a-zA-Z\s]+$/;
  if (!regexName.test(this.form.nom)) {
    this.alertName = 'Veuillez saisir un nom valide!';
  } else {
    this.alertName = '';
  }
}
  }
}
</script>

<style>
.alert{
    color:red;
}
</style>