import Dashboard from '@/views/Dashboard.vue'
import UserRegister from '@/views/UserRegister.vue'
import AllProduct from '@/views/AllProduct.vue'
import Login from '@/views/Login.vue'
import ProductRegisterVue from '@/views/ProductRegister.vue'
import DetailsProduitVue from '@/views/DetailsProduit.vue'
import EditerProduitVue from '@/views/EditerProduit.vue'
import CommandeReussie from '@/views/CommandeReussie.vue'
import CommandeEchouee from '@/views/CommandeEchouee.vue'
import CommandeEmballee from '@/views/CommandeEmballee.vue'
import CommandeExpediee from '@/views/CommandeExpediee.vue'
import CommandeLivree from '@/views/CommandeLivree.vue'
import DetailsCommandeVue from '@/views/DetailsCommande.vue'
import EmballerCommandeVue from '@/views/EmballerCommande.vue'
import AllUserVue from '@/views/AllUser.vue'
import AllDecaissement from '@/views/AllDecaissement.vue'
import AllBoutique from '@/views/AllBoutique.vue'
import DetailsBoutique from '@/views/DetailsBoutique.vue'
import EditerBoutique from '@/views/EditerBoutique.vue'
import ProduitBoutique from '@/views/ProduitBoutique.vue'
import AllSearch from '@/views/AllSearch.vue'
import AllPanier from '@/views/AllPanier.vue'
import AllTransaction from '@/views/AllTransaction.vue'
import CommandeAttente from '@/views/CommandeAttente.vue'
import ListeEleEmballer from '@/views/ListeEleEmballer.vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: Login,
    beforeEnter: (to, from, next) => {
      const user = JSON.parse(localStorage.getItem('user')||'null');
      // const user = JSON.parse(sessionStorage.getItem('user')|| 'null');
      if (user && user.token) {
        // L'utilisateur est authentifié, donc nous lui permettons l'accès
        next('/dashboard');
      } else {
        // L'utilisateur n'est pas authentifié, donc nous le redirigeons vers la page de connexion
        next();
      }

    }
  },
  {
    path:'/dashboard',
    name:'dashboard',
    component:Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path:'/user-register',
    name:'user-register',
    component:UserRegister,
    meta: { requiresAuth: true },
  },
  {
    path:'/all-user',
    name:'all-user',
    component:AllUserVue,
    meta: { requiresAuth: true, reload:true },
  },
  {
    path:'/all-produit',
    name:'all-produit',
    component:AllProduct,
    meta: { requiresAuth: true, reload:true },
  },
  {
    path:'/all-search',
    name:'all-search',
    component: AllSearch,
    meta: { requiresAuth: true },
  },
  {
    path:'/all-panier',
    name:'all-panier',
    component: AllPanier,
    meta: { requiresAuth: true },
  },
  {
    path:'/all-transaction',
    name:'all-transaction',
    component: AllTransaction,
    meta: { requiresAuth: true },
  },
  {
    path:'/produit-register',
    name:'produit-register',
    component: ProductRegisterVue,
    meta: { requiresAuth: true },
  },
  {
    path:'/produit-details/:id_produit',
    name:'produit-details',
    component: DetailsProduitVue,
    meta: { requiresAuth: true },
  },
  {
    path:'/produit-editer/:id_produit',
    name:'produit-editer',
    component: EditerProduitVue,
    meta : { requiresAuth:true }
  },
  {
    path:'/commande-reussie',
    name:'commande-reussie',
    component: CommandeReussie,
    meta: { requiresAuth: true },
  },
  {
    path:'/commande-echouee',
    name:'commande-echouee',
    component: CommandeEchouee,
    meta: { requiresAuth: true },
  },
  {
    path:'/commande-attente',
    name:'commande-attente',
    component: CommandeAttente,
    meta: { requiresAuth: true },
  },
  {
    path:'/commande-emballee',
    name:'commande-emballee',
    component: CommandeEmballee,
    meta: { requiresAuth: true },
  },
  {
    path:'/commande-expediee',
    name:'commande-expediee',
    component: CommandeExpediee,
    meta: { requiresAuth: true },
  },
  {
    path:'/list-ele-emballer/:id_order',
    name:'list-ele-emballer',
    component: ListeEleEmballer,
    meta: { requiresAuth: true },
  },
  {
    path:'/commande-livree',
    name:'commande-livree',
    component: CommandeLivree,
    meta: { requiresAuth: true },
  },
  {
    path:'/commande-details/:id_order',
    name:'commande-details',
    component: DetailsCommandeVue,
    meta: { requiresAuth: true },
  },
  {
    path:'/emballer-commande/:data',
    name:'emballer-commande',
    component: EmballerCommandeVue,
    meta: { requiresAuth: true },
  },
  {
    path:'/all-decaissement/',
    name:'all-decaissement',
    component: AllDecaissement,
    meta: { requiresAuth: true },
  },
  {
    path:'/all-boutique/',
    name:'all-boutique',
    component: AllBoutique,
    meta: { requiresAuth: true },
  },
  {
    path:'/boutique-details/:id_boutique',
    name:'boutique-details',
    component: DetailsBoutique,
    meta: { requiresAuth: true },
  },
  {
    path:'/boutique-editer/:id_boutique',
    name:'boutique-editer',
    component: EditerBoutique,
    meta: { requiresAuth: true },
  },
  {
    path:'/produit-boutique/:id_boutique',
    name:'produit-boutique',
    component: ProduitBoutique,
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const user = JSON.parse(localStorage.getItem('user') || 'null');
  // const user = JSON.parse(sessionStorage.getItem('user')|| 'null');

  if (requiresAuth && (!user || !user.token)) {
    // Si la route nécessite une authentification et que l'utilisateur n'est pas authentifié, rediriger vers la page de connexion
    next('/');
  } else {
    // Sinon, permettre l'accès à la route
    next();
  }

});
// router.afterEach((to, from) => {
//   // const user = JSON.parse(localStorage.getItem('user') || 'null');
//   const user = JSON.parse(sessionStorage.getItem('user')|| 'null');
//   if (user.token){
//     setTimeout(function() {
//       location.reload();
//     }, 1);
//   }
  
// });
// ,
  // {
  //   path: "/:catchAll(.*)",
  //   name: "Not Found",
  //   component: NotFoundPage
  // },
export default router
