<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content container"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--Editer Produit--->
                    <div class="row">
                    <!-- Column -->
                    <div class="col-lg-12">
                        <div class="material-card card">
                            <div class="card-body">
                                <form @submit.prevent="updateproduit">
                                    <div class="form-body">
                                        <h5 class="card-title">Modifier Produit</h5>
                                        <hr>
                                        <!--/row-->
                                        <div class="row">
                                            <div class="col-md-4">
                                                <h5 class="card-title mt-3">Couverture du produit</h5>
                                                <div class="el-element-overlay">
                                                    <div class="el-card-item">
                                                        <div class="el-card-avatar el-overlay-1"> <img :src="`http://apiy.htvann.com/${info.cover_img}`"/>
                                                            <div class="el-overlay">
                                                                <ul class="list-style-none el-info">
                                                                    <li class="el-item"><a class="btn default btn-outline image-popup-vertical-fit el-link" :href="`http://apiy.htvann.com/${info.cover_img}`"><i class="icon-magnifier"></i></a></li>
                                                                    <li class="el-item"><a class="btn default btn-outline el-link" :href="`http://apiy.htvann.com/${info.cover_img}`"><i class="icon-link"></i></a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="control-label">Nom</label>
                                                    <input type="text" id="first" class="form-control" v-model="info.sounom"> 
                                                </div>
                                            </div>
                                            <!--/span-->
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="particle" class="control-label">Article</label>
                                                    <select class="form-control custom-select" v-model="info.article" required>
                                                        <option value="">--Selectionner Article--</option>
                                                        <template v-if="tabarticles.length > 0">
                                                        <option v-for="article in tabarticles" :key="article.id" :value="article.id" :selected="article.id === info.article">{{ article.article }}</option>
                                                        </template>
                                                        <template v-else>
                                                        <option value="">Aucun article trouvé</option>
                                                        </template>
                                                    </select>
                                                </div>
                                            </div>
                                            <!--/span-->
                                        </div>
                                        <!--/row-->
                                        <!--/row-->
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="pcategorie" class="control-label">Cat&eacute;gorie</label>
                                                    <select class="form-control custom-select" required v-model="info.categorie">
                                                        <option value="">--Selectionner Cat&eacute;gorie--</option>
                                                        <template v-if="tabcategories.length > 0">
                                                        <option v-for="category in tabcategories" :key="category.id" :value="category.id" :selected="category.id === info.categorie">{{ category.categorie }}</option>
                                                        </template>
                                                        <template v-else>
                                                        <option value="">Aucun Catégorie trouvé</option>
                                                        </template>
                                                    </select>
                                                </div>
                                            </div>
                                            <!--/span-->
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label>Disponible</label>
                                                    <br/>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="oui" :value="1" v-model="info.disponibilite" class="custom-control-input" :checked="info.disponibilite==1">
                                                        <label class="custom-control-label" for="oui">Oui</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="non" :value="0" v-model="info.disponibilite" class="custom-control-input" :checked="info.disponibilite==0">
                                                        <label class="custom-control-label" for="non">Non</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--/span-->
                                        </div>
                                        <!--/row-->
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="pscategorie" class="control-label">Sous-Cat&eacute;gorie</label>
                                                    <select class="form-control custom-select" required v-model="info.scategorie">
                                                        <option value="">--Selectionner Sous-Cat&eacute;gorie--</option>
                                                        <template v-if="tabsoucategories.length > 0">
                                                        <option v-for="scategory in tabsoucategories" :key="scategory.id" :value="scategory.id" :selected="scategory.id===info.scategorie">{{ scategory.name }}</option>
                                                        </template>
                                                        <template v-else>
                                                        <option>Aucun Sous-Catégorie trouvé</option>
                                                        </template>
                                                    </select>
                                                </div>
                                            </div>
                                            <!--/span-->
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="pprix">Price</label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-money"></i></span>
                                                        </div>
                                                        <input type="number" class="form-control" placeholder="price" v-model="info.prix" aria-label="price" aria-describedby="basic-addon1">
                                                    </div>
                                                </div>
                                            </div>
                                            <!--/span-->
                                        </div>
                                        <!--/row-->
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="prabais">Rabais</label>
                                                    <div class="input-group mb-3">
                                                        <input type="number" id="prabais" v-model="info.rabais" class="form-control" placeholder="Rabais" aria-label="price" aria-describedby="basic-addon1">
                                                    </div>
                                                </div>
                                            </div>
                                            <!--/span-->
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="pstock">Stock</label>
                                                    <div class="input-group mb-3">
                                                        <input type="number" id="pstock" v-model="info.stock" class="form-control" placeholder="Stock" aria-label="price" aria-describedby="basic-addon1">
                                                    </div>
                                                </div>
                                            </div>
                                            <!--/span-->
                                        </div>
                                        <!--/row-->
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="ppoid">Poids</label>
                                                    <div class="input-group mb-3">
                                                        <input type="number" id="ppoid" v-model="info.poid" class="form-control" placeholder="Poids" aria-label="price" aria-describedby="basic-addon1">
                                                    </div>
                                                </div>
                                            </div>
                                            <!--/span-->
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="pvolume">Volume</label>
                                                    <div class="input-group mb-3">
                                                        <input type="number" id="pvolume" v-model="info.volume" class="form-control" placeholder="Stock" aria-label="price" aria-describedby="basic-addon1">
                                                    </div>
                                                </div>
                                            </div>
                                            <!--/span-->
                                        </div>
                                        <h5 class="card-title mt-5">Description Produit</h5>
                                        <div class="row">
                                            <div class="col-md-12 ">
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="4" v-model="info.description" ></textarea>
                                                </div>
                                            </div>
                                        </div>                                    
                                        <hr> 
                                        </div>
                                    <div class="form-actions mt-5">
                                        <button type="submit" class="btn btn-success"> <i class="fa fa-check"></i> Save</button>
                                        <router-link :to="{ name: 'produit-details', params: { id_produit: info.id } }" class="text-inverse pr-2 btn btn-warning float-right text-light cancel" title="Modifier Info"><i class="fa fa-close" aria-hidden="true"></i> &nbsp;Cancel</router-link>
                                        <!-- <a type="button" href="/produits/details/{{produit.id}}" class="btn btn-dark">Cancel</a> -->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- Column -->
                </div>
                <!--End Editer Produit--->                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
</template>

<script>
import axios from 'axios';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:"EditerProduit",
components:{
    HeaderTopBarVue,
    AsideMenuVue
},
data() {
    return {
      info:{
        id:'',
        sounom:'',
        prix:'',
        description:'',
        disponibilite:0,
        categorie:'',
        scategorie:'',
        article : '',
        poid:0,
        rabais:0,
        stock:0,
        volume:0,
        stock:'',
        boutique:'',
        created :'',
        cover_img:''
      },
      tabarticles:[],
	  tabcategories :[],
	  tabsoucategories:[]
    };
  },
  created(){
    const code_produit = this.$route.params.id_produit;
    this.info.id = code_produit; 
    // console.log(code_produit);
    axios.all([
        axios.get(`${base_url}/articles`),
        axios.get(`${base_url}/categories`),
        axios.get(`${base_url}/souscategories`),
        axios.get(`${base_url}/produit?produit_id=${code_produit}`)
    ])
    .then(axios.spread((res1,res2,res3,res4)=>{
        const produit = res4.data[0]
        // console.log(produit);
        if(produit){
                // console.log(produit);
                // console.log(produit.id);
                // this.info.id = produit.id;
                this.info.sounom =produit.sounom;
                this.info.prix = produit.prix;
                this.info.disponibilite = produit.disponibilite;
                this.info.categorie = produit.categorie_id;
                this.info.scategorie = produit.sous_categorie_id;
                this.info.article = produit.article_id;
                this.info.poid = produit.poid;
                this.info.volume = produit.volume;
                this.info.stock = produit.stock;
                this.info.rabais = produit.rabais;
                this.info.boutique = produit.boutique.boutique;
                this.info.created = produit.created;
                this.info.cover_img = produit.cover_img;
                this.info.description = produit.description;
                this.tabarticles = res1.data;
                this.tabcategories = res2.data;
                this.tabsoucategories = res3.data;
            }else{
                this.$router.push('/all-produit');
            }
        
		// console.log(res3.data);
	}))
        .catch(err=>{
            console.log(err);
        });
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    updateproduit(){
        const $data = {
			id:this.info.id,
			sounom:this.info.sounom,
            article_id:this.info.article,
            categorie_id:this.info.categorie,
            sous_categorie_id:this.info.scategorie,
            description:this.info.description,
            prix:this.info.prix,
            disponibilite : this.info.disponibilite,
            poid:this.info.poid,
            volume:this.info.volume,
            stock:this.info.stock,
            rabais:this.info.rabais
		}
        axios.put(`${base_url}/produit`,$data)
        .then(response=>{
            console.log(`-------Felicitation!-------------`);
            if(response.status==200){
                console.log(response.data)
                this.$router.push({ 
                    name: 'produit-details',
                     params: { id_produit: this.info.id },
                     query: { message: 'La modification a été effectuée avec succès!' }
                     });
                setTimeout(function() {
                        location.reload();
                    },1);
                    // res.redirect(`/produits/details/${response.data.product.id}?sucess="Update Réussie!"`);
            }
            else{
                this.$router.push({ name: 'all-produit', params: { id_produit: this.info.id } });
                //res.redirect(`/produits/register?error='Insertion Non Réussie!'&name=${req.body.pname}&description=${req.body.pdescription}&article=${req.body.particle}&categorie=${req.body.pcategorie}&scategorie=${req.body.pscategorie}&boutique=${req.body.pboutique}&geodepartement=${req.body.pgeodepartement}&commune=${req.body.pgeocommune}`);
            }
        })
        .catch(err=>{
            console.log(err);
        });
    },
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        }, 1);
    }
  }
}
</script>

<style>
.fa-close{
    color:red;
}
</style>