<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                      <!--Contenu ALLUser-->
                      <div class="row">
                                <!-- Column -->
                                <div class="col-lg-12">
                                    <div class="material-card card">
                                        <div class="card-body col-auto">
                                            <h2 style="margin-top:0px;">Produits dans les paniers <span class="badge badge-inverse badge-pill ml-auto mr-3 font-medium px-2 py-1">{{paniers.length}}</span> </h2><br>
                                        <!-- <hr class="mt-3 mb-4"> -->
                                            <div class="table-responsive">
                                                <table class="table product-overview" id="zero_config" v-if="paniers.length>0">
                                                    <tr>
                                                            <th>ID</th>
                                                            <th>Photo</th>
                                                            <th>Product</th>
                                                            <th>Price</th>
                                                            <th>Apparitions</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    <tbody>
                                                            <tr v-if="!paniers.length">
                                                            <td colspan="7">Loading.....</td>
                                                            </tr>
                                                            <tr v-else v-for="panier in paniers" :key="panier.produit">
                                                                <td>#{{panier.produit}}</td>
                                                                <td> <img :src="`http://apiy.htvann.com/${panier.cover_img}`" alt="iMac" width="80"> </td>
                                                                <td>{{panier.sounom}}</td>
                                                                <td>{{panier.prix}} HTG</td>
                                                                <!-- <td> <span class="label label-success font-weight-100">Paid</span> </td> -->
                                                                <td>{{panier.total_apparitions}} paniers</td>
                                                                <td>
                                                                    <!-- <router-link :to="{ name: 'produit-details', params: { id_produit: produit.id } }" class="text-inverse pr-2" data-toggle="tooltip" title="Details"><i class="fa fa-eye"></i></router-link> -->
                                                                    <router-link :to="{ name: 'produit-details', params: { id_produit: panier.produit } }" @click="redirectTo(`/produit-details/${panier.produit}`);event.preventDefault();" class="text-inverse pr-2" data-toggle="tooltip" title="Details"><i class="fa fa-eye"></i></router-link>
                                                                    <router-link :to="{ name: 'produit-editer', params: { id_produit: panier.produit } }" @click="redirectTo(`/produit-editer/${panier.produit}`);event.preventDefault();" class="text-inverse pr-2" data-toggle="tooltip" title="Edit"><i class="fa fa-edit"></i></router-link>
                                                                    <router-link to="javascript:void(0)" class="text-inverse" title="Delete" data-toggle="tooltip"><i class="fa fa-trash"></i></router-link>
                                                                </td>
                                                            </tr>                             
                                                        
                                                    </tbody>
                                                </table>
                                                <p v-else>Loading...</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Column -->
</div>


                      <!--End Contenu ALLUser-->
                            
                        
                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->

</template>

<script>
import axios from 'axios';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:'AllPanier',
components: {
    HeaderTopBarVue,
    AsideMenuVue
  },
  data(){
    return{
        paniers:[],
    }
  },
  async created(){
    // window.location.reload();
// this.$router.go(0);
       axios.get(`${base_url}/all_panier`)
            .then((response)=>{
                console.log(response.data);
                this.paniers=response.data;
                console.log(this.paniers);
            })
            .catch((error)=>{
                alert('Une erreur est survenue!');
                console.error(error);
            })
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        }, 1);
    }
  }
}
</script>

<style>

</style>