<template>
  <HeaderTopBarVue/>
  <AsideMenuVue/>
  <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--AllProduit--->
                <div class="row">
                            <!-- Column -->
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="material-card card">
                                        <div class="card-body">
                                            <h2 style="margin-top:0px;">Les produits à expédier <span class="badge badge-inverse badge-pill ml-auto mr-3 font-medium px-2 py-1">{{products.length}}</span></h2>
                                        <!-- <hr class="mt-3 mb-4"> -->
                                            <div class="table-responsive">
                                                <table class="table product-overview" id="zero_config" v-if="products.length>0">
                                                    <thead> 
                                                        <tr>
                                                            <th>Photo</th>
                                                            <th>Product</th>
                                                            <th>Quantité</th>
                                                            <th>Trace</th>
                                                            <th>Personne</th>
                                                            <th>Adresse</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                            <tr v-for="product in products" :key="product.commandes_id">
                                                                <td class="col-lg-3 col-md-3 col-sm-6 colonne-etroite"><img :src="`http://apiy.htvann.com/${product.cover_img}`" class="img-responsive img-fluid img-thumbnail"></td>
                                                                <td>{{product.sounom}}</td>
                                                                <td>{{product.quantite_element}}</td>
                                                                <td class="font-500">{{product.tras}}</td>
                                                                <td>{{product.nom}}</td>
                                                                <td>{{product.rue}}, {{product.zone}}</td>
                                                            
                                                                <td>
                                                                    <!-- <router-link :to="{ name: 'produit-details', params: { id_produit: produit.id } }" class="text-inverse pr-2" data-toggle="tooltip" title="Details"><i class="fa fa-eye"></i></router-link> -->
                                                                    <!-- <router-link :to="{ name: 'commande-details', params: { id_order: product.commande_id } }" @click="redirectTo(`/commande-details/${product.commande_id}`);event.preventDefault();" class="text-inverse pr-2" data-toggle="tooltip" title="Details"><i class="fa fa-eye"></i></router-link> -->
                                                                    <a href="#" @click="openModal(`${product.id_commandeelements}`)" data-toggle="modal" :data-bs-target="'expedier'+product.id_commandeelements" class="text-inverse" type="button" title="Expedier"><i class="fa fa-truck"></i></a>
                                                                </td>
                                                                <!-- The Modal -->
                                                                <div v-if="showModal" class="modal show" :id="'expedier'+product.id_commandeelements" style="display: block;">
                                                                    <div class="modal-dialog">
                                                                        <div class="modal-content">
                                                                        <div class="modal-header">
                                                                            <h3 class="modal-title mx-auto">Trace de l'element</h3>
                                                                            <button type="button" class="close" @click="closeModal">
                                                                            <span>&times;</span>
                                                                            </button>
                                                                        </div>
                                                                        <div class="modal-body">
                                                                            <input id="tras_element" type="text" v-model="code_tracking" class="form-control" @change="expedierCommande()">
                                                                        </div>
                                                                        <div class="modal-footer">
                                                                            <button type="button" class="btn btn-secondary" @click="closeModal">Fermer</button>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    </div>
                                                            </tr>                                  
                                                        
                                                    </tbody>
                                                </table>
                                                <p v-else>Aucune Commande à expédier.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <!-- Column -->
                        </div>

                <!--End AllProduit--->
                            
                        
                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
</template>

<script>
import axios from 'axios';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'jquery/dist/jquery.min.js';
// import 'popper.js/dist/popper.min.js';
// import 'bootstrap/dist/js/bootstrap.min.js';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:"CommandeExpediee",
components: {
    HeaderTopBarVue,
    AsideMenuVue
  },
  data() {
    return {
      products: [],
      code_tracking:'',
      kod_element:'',
      showModal: false
    };
  },
  async created(){
       axios.get(`${base_url}/elements_status?status=1`)
            .then((response)=>{
                console.log(response.data);
                // return false;
                this.products=response.data;
                
            })
            .catch((error)=>{
                alert('Une erreur est survenue!');
                console.error(error);
            })
  },
  mounted(){
    // if(!this.code_tracking){
    //     this.$refs.tras.focus();
    // }
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        }, 1);
    },
    expedierCommande(){
        console.log(this.kod_element);
        console.log(this.code_tracking);  
        const infodata = {
            element:this.kod_element,
            tracking:this.code_tracking
		}
        console.log(infodata);
        // return false;
        if(this.code_tracking){
            axios.put(`${base_url}/expedier-produit`,infodata)
            .then((response)=>{
                console.log(response.data);
                if(response.data.Element){
                    this.$router.go(0);
                }
            })
            .catch((err)=>{
                console.log(err);
            })
        this.showModal = false; 
        this.code_tracking='';
        }
    },
    openModal(element) {
        this.kod_element=element;
        console.log(this.kod_element); 
      this.showModal = true;
        // return false;
      this.$nextTick(() => {
        document.getElementById('tras_element').focus();
      });
    },
    closeModal() {
      this.showModal = false;
    }
  }
}
</script>

<style>

</style>