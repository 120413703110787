<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                      <!--Contenu ALLUser-->
                      <div class="row">
        <!-- Column -->
        <div class="col-lg-12">
            <div class="material-card card">
                <div class="card-body">
                    <h2 style="margin-top:0px;">Liste Boutiques <span class="badge badge-inverse badge-pill ml-auto mr-3 font-medium px-2 py-1">{{boutiques.length}}</span></h2><br>
                <!-- <hr class="mt-3 mb-4"> -->
                    <div class="table-responsive">
                        <table class="table product-overview" id="myTable" v-if="boutiques.length>0">
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th onclick="sortTable(1)">Boutique <i class="fa fa-sort-desc" aria-hidden="true"></i><i class="fa fa-sort-asc" aria-hidden="true"></i></th>
                                    <th>Slogan</th>
                                    <th onclick="sortTable(3)">Proprietaire <i class="fa fa-sort-desc" aria-hidden="true"></i><i class="fa fa-sort-asc" aria-hidden="true"></i></th>
                                    <th onclick="sortDateTable(4)">Creation <i class="fa fa-sort-desc" aria-hidden="true"></i></th>
                                    <th onclick="sortTable(5)">Status <i class="fa fa-sort-desc" aria-hidden="true"></i></th>
                                    <th>Action</th> 
                                </tr>
                            </thead> 
                            <tbody>
                                    <tr v-for="boutique in boutiques" :key="boutique.code_boutique">
                                        <td>#{{boutique.code_boutique}}</td>
                                        <td>{{boutique.boutique_name}}</td>
                                        <td>{{boutique.boutique_slogan}}</td> 
                                        <td>{{boutique.sexe_user === 1 ? 'M. ' : 'Mme ' }}{{boutique.nom_user}} {{boutique.prenom_user}}</td>
                                        <td>{{ formatDate(boutique.date_creation)}}</td> 
                                        <td>{{boutique.role_user === 'VENDEUR' ? 'Valid&eacute;' : 'non-valid&eacute;' }}</td>
                                        <td>
                                            <router-link :to="{ name: 'boutique-details', params: { id_boutique: boutique.code_boutique } }" title="Details" @click="redirectTo(`/boutique-details/${boutique.code_boutique}`);event.preventDefault();"><i class="fa fa-eye"></i></router-link>
                                            &nbsp;&nbsp;<router-link :to="{ name: 'boutique-editer', params: { id_boutique: boutique.code_boutique } }" title="Edit" @click="redirectTo(`/boutique-editer/${boutique.code_boutique}`);event.preventDefault();"><i class="fa fa-edit"></i></router-link>
                                            &nbsp;&nbsp;<router-link :to="{ name: 'produit-boutique', params: { id_boutique: boutique.code_boutique } }" title="Produits" @click="redirectTo(`/produit-boutique/${boutique.code_boutique}`);event.preventDefault();"><i class="fa fa-briefcase"></i></router-link>
                                        </td>
                                    </tr>                                
                            </tbody>
                        </table>
                        <p v-else>Loading...</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Column -->
</div>


                      <!--End Contenu ALLUser-->
                            
                        
                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->

</template>

<script>
import axios from 'axios';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:'AllBoutique',
components: {
    HeaderTopBarVue,
    AsideMenuVue
  },
  data(){
    return{
        boutiques:[],
    }
  },
  async created(){
       axios.get(`${base_url}/boutiques`)
            .then((response)=>{
                // console.log(response.data);
                this.boutiques=response.data;
                // console.log(this.boutiques);
            })
            .catch((error)=>{
                alert('Une erreur est survenue!');
                console.error(error);
            })
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        }, 1);
    }
  }
}
</script>

<style>

</style>