<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--Details Commande--->
                    
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="white-box text-center col-12"> 
                                                <!-- <img src="https://apiy.htvann.com/public/<?= h($commandeelement->produit->cover_img) ?>" class="img-responsive col-12"> </div> -->
                                        </div>
                                        
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h3 class="box-title mt-5">Information Générale</h3>
                                            <div class="table-responsive">
                                    <table class="table table-bordered" v-if="products.length>0">
                                        <thead>
                                            <tr>
                                                <th>Photo</th>
                                                <th>Produit</th>
                                                <th>Description</th>
                                                <th>Size</th>
                                                <th>Couleur</th>
                                                <th>Quantit&eacute;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="product in products" :key="product.commandes_id">
                                                <td class="col-lg-3 col-md-3 col-sm-6 colonne-etroite"><img :src="`http://apiy.htvann.com/${product.cover_img}`" class="img-responsive img-fluid img-thumbnail"></td>
                                                <td>{{product.sounom}}</td>
                                                <td class="font-500">{{product.description}}</td>
                                                <td class="font-500">{{product.size}}</td>
                                                <td class="font-500">{{product.couleur}}</td>
                                                <td>{{product.quantite_element}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p v-else>Aucun produit pour cette commande.</p>
                                </div>
                                        </div>

                                        <div class="col-12">
                                            <h3> Label d'expedition </h3>
                                            <div class="card">
                                                <div class="body-card" id="divToPrint">
                                                    <table class="table" style="border: 1px solid black;">
                                                        <tr style="border: 1px solid black; height:120px;">
                                                            <td style="border: 1px solid black; text-align:center; padding:10px;">

                                                                <h1>EX</h1>
                                                                htvann.com

                                                            </td>
                                                            <td style="border: 1px solid black; padding:10px;">

                                                                <p id="info-htvann">
                                                                    htvann.com | Dp01PV <br/>
                                                                    177, Faubert ,Pétion Ville, Ouest Haïti <br/>
                                                                    Expédié le : {{formatDate(currentDate)}} <br/>
                                                                    Expresse : 4
                                                                </p>

                                                            </td>
                                                        </tr>

                                                        <tr style="border: 1px solid black;  height:150px;">
                                                            <td colspan="2" style="border: 1px solid black; padding:5px;">
                                                                A : <b>{{fullname}}</b>
                                                                <p>{{adresse}}</p>
                                                            </td>
                                                        </tr>

                                                        <tr style="border: 1px solid black;">
                                                            <td colspan="2" style="border: 1px solid black; padding-right:10px; padding-left:10px;">
                                                                <div class="col-12" style="display: flex; flex-direction: column; justify-content: center;align-items: center;">
                                                                    <div id="info-pdf417" hidden>
                                                                    htvann.com | Dp01PV <br/>
                                                                    177, Faubert, Pétion Ville, Ouest Haïti <br/>
                                                                    Expédié le : {{formatDate(currentDate)}} <br/>
                                                                    Adresse : <p id="adres"> </p> <br/>
                                                                    Expresse : 4<br/>
                                                                </div>
                                                                    <p>
                                                                    <img src="" id="pdf417" alt="">
                                                                    <!-- <canvas id="pdf417"></canvas> -->
                                                                    </p>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr style="border: 1px solid black; padding:10px;">
                                                            <td colspan="2" style="border: 1px solid black; padding:10px;">
                                                                <h4># DS : tras</h4>
                                                                <div class="col-12" style="display: flex; flex-direction: column; justify-content: center;align-items: center;">
                                                                    <p>
                                                                     <img id="code128">
                                                                    </p>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </table>
                                                    <small style="margin-top:10px; display: flex; flex-direction: column; justify-content: center;align-items: center;">
                                                        <!-- <?= $this->Html->image('logo/logo.png',['width' => '50']); ?> -->
                                                        www.htvann.com</small>
                                                </div>
                                                <!-- <?//= $this->Form->create($article) ?> -->
                                                <button type="submit" @click="printDiv" class="btn btn-primary">Print</button>
                                                <!-- <?//= $this->Form->end() ?> -->
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Column -->
                    </div>
                    </div>
                    
                <!--End Details Commande--->                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
</template>

<script>
import axios from 'axios';
import bwipjs from 'bwip-js';
// import { generateBarcode } from 'pdf417-generator';
// import JsBarcode from 'jsbarcode';
// import Master from '@/components/Master.vue';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:"EmballerCommande",
components:{
    HeaderTopBarVue,
    AsideMenuVue
},
data() {
    return {
      products: [],
      total:0,
      code : '',
      code_tras:'',
      barcodeTextpdf417:'',
      adresse:'',
      fullname:'',
      currentDate: new Date().toLocaleDateString('en-US', {
        timeZone: 'America/New_York'
      })
    };
  },
  created(){
    const listeElements = JSON.parse(this.$route.params.data);
    console.log(listeElements);
    // return false;
            if(listeElements){
                this.products = [...listeElements];
                // console.log(this.products); 
                this.code = listeElements[0].commande_id;
                this.code_tras = listeElements[0].tras;
                this.adresse =`${listeElements[0].rue}, ${listeElements[0].zone}`;
                // console.log('testoooo');
                this.fullname = listeElements[0].nom;
                // console.log(this.code_tras);
                // console.log(this.adresse);
                // console.log(this.fullname);

            }else{
                this.$router.push('/all-produit');
            }
        
  },
  mounted() {
    // window.addEventListener('afterprint', this.redirectToExpredier);
    document.getElementById('adres').innerHTML=this.adresse;
    /**Generateur barcode : PDF417
                 * const canvas = document.getElementById('pdf417');
                 *  infoPdf : text a encoder
                **/
               const infoPdf = document.getElementById('info-pdf417').innerHTML.replace(/<br>/g, "\n").replace(/<[^>]*>/g, '');
               console.log(infoPdf);
                let canvaspdf417 = document.createElement('canvas');
                bwipjs.toCanvas(canvaspdf417, {
                bcid: 'pdf417', //pdf417 :type de code à barres (PDF417 dans cet exemple)
                text: infoPdf, // texte à encoder
                scale: 2, // facteur d'échelle
                height: 10, // hauteur du code à barres
                includetext: true, // inclure le texte dans le code à barres
                textxalign: 'center', // alignement horizontal du texte
                });
                document.getElementById('pdf417').src = canvaspdf417.toDataURL('image/png');

                
                /** Generateur barcode : code128
                 * const canvas128 = document.getElementById('code128');
                **/
                let canvas128 = document.createElement('canvas');
                bwipjs.toCanvas(canvas128, {
                bcid: 'code128', //pdf417 :type de code à barres (PDF417 dans cet exemple)
                text: this.code_tras, // texte à encoder
                scale: 2, // facteur d'échelle
                height: 10, // hauteur du code à barres
                includetext: true, // inclure le texte dans le code à barres
                textxalign: 'center', // alignement horizontal du texte
                });
                document.getElementById('code128').src = canvas128.toDataURL('image/png');

},
  methods:{
    redirectToExpredier(){
        this.$nextTick(() => {
      // Redirection vers une autre route
    //   this.$router.push('/nouvelle-page');
        this.router.push('/commande-expediee')
    });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`
    },
    printDiv(){
        var mywindow = window.open('', 'PRINT','');

        mywindow.document.write('<html><body >');
        mywindow.document.write(document.getElementById('divToPrint').innerHTML);
        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/ 

        mywindow.print();
        mywindow.close();

        axios.get(`${base_url}/details_commande?code_commande=${this.code}`)
        .then((response)=>{
            const kantiteTrasVide = response.data.filter((item)=>!item.tras).length;
            if(kantiteTrasVide==0){
                const infodata = {
                    commande:this.code
                }
                axios.put(`${base_url}/confimerEmballage`,infodata)
                .then(response=>{
                    console.log(`-------Felicitation!-------------`);
                    if(response.status==200){
                        console.log(response.data)
                        console.log("La confirmation de l'emballage n'est pas reussie!")
                            this.$router.push('/commande-emballee');
                        setTimeout(() => {
                            this.$router.go(0);
                        // Redirection vers une autre route après seconde ...
                        },10);
                    }
                    else{
                    console.log("La confirmation de l'emballage n'est pas reussie!")
                        // this.$router.push({ name: 'all-produit', params: { id_produit: this.info.id } });
                        //res.redirect(`/produits/register?error='Insertion Non Réussie!'&name=${req.body.pname}&description=${req.body.pdescription}&article=${req.body.particle}&categorie=${req.body.pcategorie}&scategorie=${req.body.pscategorie}&boutique=${req.body.pboutique}&geodepartement=${req.body.pgeodepartement}&commune=${req.body.pgeocommune}`);
                    }
                })
                .catch(err=>{
                    console.log(err);
                });
            }
            else{
                this.$router.push({ name: 'list-ele-emballer', params: { id_order: this.code} });
                setTimeout(() => {
                    this.$router.go(0);
                    },10);
            }
        })
        .catch((error)=>{
            console.log(error);
        })

        
        // window.location = '/mngx/commandeelements/confirmambalage/'+<?= $commandeelement->id ?>;
        return true;
    }
  }
}
</script>

<style>
.colonne-etroite {
  width: 100px;
}
.wj-barcode-pdf417 {
        height: 90px;
        border-color: aqua;
    }
</style>