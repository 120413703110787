<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--Details Commande--->
                    
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="white-box text-center col-12"> 
                                                <!-- <img src="https://apiy.htvann.com/public/<?= h($commandeelement->produit->cover_img) ?>" class="img-responsive col-12"> </div> -->
                                        </div>
                                        
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <h3 class="box-title mt-5">Information Générale</h3>
                                            <div class="table-responsive" v-if="products.length>0">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Photo</th>
                                                            <th>Produit</th>
                                                            <th>Description</th>
                                                            <th>Size</th>
                                                            <th>Couleur</th>
                                                            <th>Quantit&eacute;</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="product in products" :key="product.commandes_id">
                                                            <td class="col-lg-3 col-md-3 col-sm-6 colonne-etroite"><img :src="`http://apiy.htvann.com/${product.cover_img}`" class="img-responsive img-fluid img-thumbnail"></td>
                                                            <td>{{product.sounom}}</td>
                                                            <td class="font-500">{{product.description}}</td>
                                                            <td class="font-500">{{product.size}}</td>
                                                            <td class="font-500">{{product.couleur}}</td>
                                                            <td>{{product.quantite_element}}</td>
                                                            <template v-if="!product.tras">
                                                                <td><input type="checkbox" :value="product.id_commandeelements" @change="ajouterElement(product.id_commandeelements)"></td>
                                                            </template>
                                                            <template v-if="product.tras">
                                                                <td><p>Deja Emballe!</p></td>
                                                            </template>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div class="text-center mb-4">
                                                    <button type="button" class="btn btn-primary mx-auto" @click="emballerProduits" >Emballer <i class="fa fa-gift"></i></button>
                                                </div>
                                            </div>
                                            <p v-else>Aucun produit pour cette commande.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Column -->
                    </div>
                    </div>
                    
                <!--End Details Commande--->                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
</template>

<script>
import axios from 'axios';
import bwipjs from 'bwip-js';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:"ListeEleEmballer",
components:{
    HeaderTopBarVue,
    AsideMenuVue
},
data() {
    return {
      products: [],
      total:0,
      code : '',
      adresse:'',
      fullname:'',
      eleSelected:[],
      currentDate: new Date().toLocaleDateString('en-US', {
        timeZone: 'America/New_York'
      })
    };
  },
  created(){
    console.log('Ben');
    console.log(this.$route.params.id_order);
    const code_commande = this.$route.params.id_order;
    axios.get(`${base_url}/details_commande?code_commande=${code_commande}`)
        .then(response=>{
            console.log(response.data);
            // return false;
            if(response.data){
                this.products = response.data;
                this.code = response.data[0].commande_id;
            }else{
                this.$router.push('/all-produit');
            }
        })
        .catch(err=>{
            console.log(err);
        });
  },
  mounted() {
    // window.addEventListener('afterprint', this.redirectToExpredier);
},
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`
    },
    ajouterElement(id){
        //! Ben => Cette code verifie si l'id existe deja dans le tableau eleSelected,ect...
        (!this.eleSelected.includes(id))?this.eleSelected.push(id):this.eleSelected.splice(this.eleSelected.indexOf(id),1);      
        // console.log(this.eleSelected);
    },
    emballerProduits(){
        if(this.eleSelected.length>0){
            const data ={
                elements:this.eleSelected,
                code_commande:this.$route.params.id_order
            };
            console.log('yes');
            // console.log(data);
            // return false;
            axios.put(`${base_url}/emballer-produit`,data)
            .then(response=>{
                console.log('Apres emballage!');
                console.log(response.status);
                if(response.status==200){
                    console.log(response.data.elementsCommande);
                    this.$router.push({ name: 'emballer-commande', params: { data: JSON.stringify(response.data.elementsCommande) } });
                    setTimeout(() => {
                            this.$router.go(0);
                        // Redirection vers une autre route après 1 seconde
                        },1000);
                }else{
                    this.$router.push('/all-produit');
                }
            })
            .catch(err=>{
                console.log(err);
            });
        }
        else{
            console.log('no');
        }
    },
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        },1);
    }
  }
}
</script>

<style>
.colonne-etroite {
  width: 100px;
}
.wj-barcode-pdf417 {
        height: 90px;
        border-color: aqua;
    }
</style>