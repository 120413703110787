<template>
  <HeaderTopBarVue/>
    <AsideMenuVue/>
	<!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content container"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--register Produit--->
                    <div class="alert alert-success text-success" v-if="success_msg">{{success_msg}}</div>
					<h2 class="dizay">Ajouter Produit</h2>
<hr class="mt-0 mb-3">
	<div class="col-lg-12">
	<div class="row">
				<form enctype="multipart/form-data" method="POST" ref="myForm" @submit.prevent="registerproduit">
					<div class="col-sm-12">
						<div class="row">
							<div class="col-sm-6 form-group">
								<label for="particle">Choisir Article</label>
								<select class="form-control custom-select" v-model="form.particle" id="particle" required>
                                    <option value="">--Selectionner Article--</option>
                                    <template v-if="tabarticles.length > 0">
									<option v-for="article in tabarticles" :key="article.id" :value="article.id">{{ article.article }}</option>
									</template>
									<template v-else>
									<option value="">Aucun article trouvé</option>
									</template>
                                </select>
							</div>
							<div class="col-sm-6 form-group">
								<label for="pname">Nom Du Produit</label>
								<input placeholder="Produit Name" v-model="form.pname" class="form-control" @keyup="champsNameVide" @keydown="champsNameVide" @focus="champsNameVide">
                                <p v-if="alertName" class="alert"> {{ alertName }} </p>
							</div>
						</div>
                        <div class="row">
							<div class="col-sm-6 form-group">
								<label for="pcategorie">Cat&eacute;gorie</label>
								<select class="form-control custom-select" required v-model="form.pcategorie">
                                    <option value="">--Selectionner Cat&eacute;gorie--</option>
                                    <template v-if="tabcategories.length > 0">
									<option v-for="category in tabcategories" :key="category.id" :value="category.id">{{ category.categorie }}</option>
									</template>
									<template v-else>
									<option value="">Aucun Catégorie trouvé</option>
									</template>
                                </select>
							</div>		
							<div class="col-sm-6 form-group">
								<label for="pscategorie">Sous-Cat&eacute;gorie</label>
								<select class="form-control custom-select" required v-model="form.pscategorie">
                                    <option value="">--Selectionner Sous-Cat&eacute;gorie--</option>
                                    <template v-if="tabsoucategories.length > 0">
									<option v-for="scategory in tabsoucategories" :key="scategory.id" :value="scategory.id">{{ scategory.name }}</option>
									</template>
									<template v-else>
									<option value="">Aucun Sous-Catégorie trouvé</option>
									</template>
                                </select>
							</div>	
						</div>	
                        <div class="row">
                            <div class="col-sm-12 form-group">                        
							<label id="pdescription">Description</label>
							<textarea v-model="form.pdescription" rows="2" class="form-control" @keyup="champsDescriptionVide" @keydown="champsDescriptionVide" @focus="champsDescriptionVide"></textarea>
                            <p v-if="alertDescription" class="alert"> {{ alertDescription }} </p>				
                            </div>
                        </div>
						<div class="row">
							<div class="col-sm-4 form-group">
								<label for="pboutique">Boutique</label>
								<select class="form-control custom-select" required v-model="form.pboutique">
                                    <option value="">--Boutique--</option>
									<template v-if="tabboutiques.length > 0">
									<option v-for="boutik in tabboutiques" :key="boutik.code_boutique" :value="boutik.code_boutique">{{ boutik.boutique_name }}</option>
									</template>
									<template v-else>
									<option value="">Aucune Boutique trouvé</option>
									</template>
                                </select>
							</div>	
							<div class="col-sm-4 form-group">
								<label for="pgeodepartement">D&eacute;partement</label> 
								<select class="form-control custom-select" required  v-model="form.pgeodepartement">
                                    <option value="">--D&eacute;partement G&eacute;o--</option>
                                    <template v-if="tabgeodepartements.length > 0">
									<option v-for="dgeo in tabgeodepartements" :key="dgeo.id" :value="dgeo.id">{{ dgeo.geodepartement }}</option>
									</template>
									<template v-else>
									<option value="">Aucun Departement trouvé</option>
									</template>
                                </select>
							</div>	
							<div class="col-sm-4 form-group">
								<label for="pgeocommune">Commune</label>
								<select class="form-control custom-select" required v-model="form.pgeocommune">
                                    <option value="">--Communes--</option>
                                    <template v-if="tabgeocommnunes.length > 0">
									<option v-for="commune in tabgeocommnunes" :key="commune.id" :value="commune.id">{{ commune.commune }}</option>
									</template>
									<template v-else>
									<option value="">Aucune Commune trouvée</option>
									</template>
                                </select>
							</div>		
						</div>						
						<div class="row">
							<div class="col-sm-4 form-group">
								<label for="pkantite">Quantit&eacute;</label>
								<input type="number" v-model="form.pkantite" required class="form-control">
							</div>	
							<div class="col-sm-4 form-group">
								<label for="pprix">Prix Produit</label>
								<input type="number" v-model="form.pprix" required class="form-control">
							</div>	
							<div class="col-sm-4 form-group">
								<label for="prabais">Rabais</label>
								<input type="number" v-model="form.prabais" class="form-control">
							</div>		
						</div>
                        <div class="row">
							<div class="col-sm-6 form-group">
								<label for="ppoids">Poids</label>
                                    <input type="number" min="1" v-model="form.ppoids" class="form-control">
							</div>		
							<div class="col-sm-6 form-group">
								<label for="pvolume">Volume</label>
                                    <input type="number" min="1" v-model.number="form.pvolume" class="form-control">
							</div>	
						</div>				
                        <div class="row">
							<div class="col-sm-6 form-group">
								<label for="pstock">Stock</label>
                                    <input type="number" min="1" v-model.number="form.pstock" id="pstock" step="any" class="form-control">
							</div>		
							<div class="col-sm-6 form-group">
								<label for="pimage">Ajouter Cover Image</label>
                                <input type="file" @change="handleFileUpload" accept="image&#47;*" id="pimage" required class="form-control">
							</div>	
						</div>				
					    <button type="submit" class="btn btn-lg btn-info btn-block">Ajouter</button>				
					</div>
				</form> 
				</div>
	</div>


                <!--End register Produit--->                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
</template>

<script>
import axios from 'axios';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
 name:"ProductRegister",
 components:{
    HeaderTopBarVue,
    AsideMenuVue
 },
 data() {
    return {
      success_msg:'',
      form :{
	  particle: '',
      pname: '',
      pcategorie:'',
      pscategorie:'',
      pdescription:'',
      pboutique:'',
      pgeodepartement:'',
      pgeocommune:'',
      pkantite:1,
      pprix:1,
      prabais:0,
      ppoids:1,
      pvolume:1,
      pstock:1,
	  fileData:null
	  },
	tabarticles:[],
	tabcategories :[],
	tabsoucategories:[],
	tabgeodepartements:[],
	tabgeocommnunes:[],
	tabboutiques:[],
    alertName:'',
    alertDescription:''
    }
  },
  async created(){
		axios.all([
        axios.get(`${base_url}/articles`),
        axios.get(`${base_url}/categories`),
        axios.get(`${base_url}/souscategories`),
        axios.get(`${base_url}/geo/departements`),
        axios.get(`${base_url}/boutiques`),
        axios.get(`${base_url}/geo/communes`)
    ])
	.then(axios.spread((res1,res2,res3,res4,res5,res6)=>{
		this.tabarticles = res1.data;
		this.tabcategories = res2.data;
		this.tabsoucategories = res3.data;
		this.tabgeodepartements = res4.data;
		this.tabboutiques = res5.data;
		this.tabgeocommnunes = res6.data;
		// console.log(res6.data);
	}))
    .catch((err) => {
        console.log(err);
    });
    
  },
  methods:{
    registerproduit(){
		const $data = {
            sounom:this.form.pname,
            article_id:this.form.particle,
            categorie_id:this.form.pcategorie,
            sous_categorie_id:this.form.pscategorie,
            description:this.form.pdescription,
            poid:this.form.ppoids,
            volume:this.form.pvolume,
            stock:this.form.pstock,
            prix:this.form.pprix,
            rabais:this.form.prabais,
            disponibilite:1,
            geo_departement_id:this.form.pgeodepartement,
            commune_id : this.form.pgeocommune,
            boutique_id:this.form.pboutique,
            file:this.form.fileData
		}
        //?Après le traitement réussi de l'envoi du formulaire
        this.$refs.myForm.reset();
        this.form ={
	  particle: '',
      pname: '',
      pcategorie:'',
      pscategorie:'',
      pdescription:'',
      pboutique:'',
      pgeodepartement:'',
      pgeocommune:'',
      pkantite:1,
      pprix:1,
      prabais:0,
      ppoids:1,
      pvolume:1,
      pstock:1,
	  fileData:null
	  };

        console.log($data);
		axios.post(`${base_url}/produit`,$data,{
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response=>{
            console.log(`-------Felicitation!-------------`);
            console.log(response);
            console.log(response.data);
            if(response.status==201){
                console.log(`${response.data.sounom} a ajout&eacute; Avec Success!`);
                this.success_msg = `Produit ajouté avec success!`;
                                  
            }
            else{
                alert('Echec!!!');
            }
        })
        .catch(err=>{
            alert('Un probbleme est survenu!!!');
            console.log(err);
        });
	},
	handleFileUpload(event) {
      this.form.fileData = event.target.files[0];
    },
    champsNameVide() {
      const regexNonVide = /\S+/;
        if (!regexNonVide.test(this.form.pname)) {
            this.alertName = 'Veuillez remplir ce champ!';
        }
        else{
            this.alertName = '';
        }
    },
    champsDescriptionVide() {
      const regexNonVide = /\S+/;
        if (!regexNonVide.test(this.form.pdescription)) {
            this.alertDescription = 'Veuillez remplir ce champ!';
        }
        else{
            this.alertDescription = '';
        }
    }
  }
}
</script>

<style>
    
/* Custom page footer */
.footer {
  padding-top: 19px;
  color: #777;
  border-top: 1px solid #e5e5e5;
}

/* Customize container */
@media (min-width: 768px) {
  .container {
    max-width: 730px;
  }
}
.container-narrow > hr {
  margin: 30px 0;
}


</style>