<template>
<!-- 
#-------------------------------------------------------------------------------
# Name: zgc
# Version: 1.0.0
#
#  Author: Ben@zode
#  Mail: pben.jacques@gmail.com
#  Telf: +509 42003583 / 48846143
# 
# Start Created: 
# Copyright: (c) zode 2023
# Licence: <your licence>
#-------------------------------------------------------------------------------
 -->
<html lang="">
    <head>
        <meta charset="utf-8"> 
        <meta http-equiv="X-UA-Compatible" content="IE=edge"> 
        <meta name="author" content="Petit-Homme Ben-Jacques" />
        <meta name="viewport" content="width=device-width, initial-scale=1">
        <!-- <link rel="stylesheet" href="/zgc_assets/bootstrap-4.3.1/css/bootstrap.min.css"> -->
        <!-- <link rel="stylesheet" href="/zgc_assets/assets/font-awesome/css/font-awesome.min.css"> -->
        <!-- <link rel="stylesheet" href="/zgc_assets/assets/css/animationText.css">
        <link rel="stylesheet" href="/zgc_assets/assets/fontawesome/css/fontawesome.min.css">
        <link rel="stylesheet" href="/zgc_assets/assets/fontawesome/css/all.min.css"> -->
        <title>ZGC</title>
    </head>
    <body>
        <div class="container mt-3">
            <!-- Injected view here-->
            
            <!-- contenu principal de l'application -->
                <slot></slot>
            
            <!-- injected view here -->
            <br>
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
        </div>
    </body>
</html>
<!--
written By : Petit-Homme Ben-Jacques
Telf : +509 42003583 / 48846143
Mail: pben.jacques@gmail.com
-->

</template>

<script>
export default {
    name:"Main"
}
</script>

<style>

</style>