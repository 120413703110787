<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                      <!--Contenu ALLUser-->
                      <div class="row">
        <!-- Column -->
        <div class="col-lg-12">
            <div class="material-card card">
                <div class="card-body">
                    <h2 style="margin-top:0px;">Liste Utilisateurs <span class="badge badge-inverse badge-pill ml-auto mr-3 font-medium px-2 py-1">{{users.length}}</span> </h2><br>
                <!-- <hr class="mt-3 mb-4"> -->
                    <div class="table-responsive">
                        <table class="table product-overview" id="zero_config"  v-if="users.length>0">
                            <thead>
                                <tr>
                                    <th>Code</th>
                                    <th>Nom</th>
                                    <th>Pr&eacute;nom</th>
                                    <th>Email</th>
                                    <th>T&eacute;l&eacute;phone</th>
                                    <th>Adresse</th>
                                    <th>Sexe</th>
                                    <th>R&ocirc;le</th>
                                    <th>Inscription</th>
                                    <th>Confirmé</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                    <tr v-for="user in users" :key="user.utilisateur">
                                        <td>#{{user.utilisateur}}</td>
                                        <td>{{user.nom_user}}</td>
                                        <td>{{user.prenom_user}}</td>
                                        <td>{{user.email_user}}</td>
                                        <td>{{user.phone_user}}</td>
                                        <td>{{user.rue_user_adresse}} {{ user.rue_user_adresse? ',' : '' }}{{user.zone_user_adresse}}</td>
                                        <td>{{ user.sexe_user === 1 ? 'Masculin' : 'Feminin' }}</td>
                                        <td>{{user.role_user}}</td>
                                        <td>{{ formatDate(user.date_creation)}}</td>
                                        <td>{{ user.confirmation === 1 ? 'Oui' : 'Non' }}</td>
                                        <td>
                                            <a href="#" class="text-inverse pr-2" data-toggle="tooltip" title="Details"><i class="fa fa-eye"></i></a>
                                            <a href="#" class="text-inverse pr-2" data-toggle="tooltip" title="Edit"><i class="fa fa-edit"></i></a>
                                        </td>
                                    </tr>                                
                            </tbody>
                        </table>
                        <p v-else>Loading...</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Column -->
</div>


                      <!--End Contenu ALLUser-->
                            
                        
                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->

</template>

<script>
import axios from 'axios';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:'AllUser',
components: {
    HeaderTopBarVue,
    AsideMenuVue
  },
  data(){
    return{
        users:[],
    }
  },
  async created(){
    // window.location.reload();
// this.$router.go(0);
       axios.get(`${base_url}/users`)
            .then((response)=>{
                console.log(response.data);
                // this.users=response.data.reverse();
                this.users=response.data;
                console.log(this.users);
                // console.log(this.users.length);
                // console.log(this.$route.path);
            })
            .catch((error)=>{
                alert('Une erreur est survenue!');
                console.error(error);
            })
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    reloaded() {
        const route = this.$route.path;
        console.log(route+'ok');
        this.$router.push(route);
        setTimeout(function() {
          location.reload();
        }, 1);
    }
  },
  mounted() {
    // if (this.$route.meta.reload) {
    //   setTimeout(function() {
    //       location.reload();
    //     }, 5000000);
    // }
  },
  activated() {
    if (this.$route.meta.reload) {
      this.$nextTick(() => {
        this.$refs.myButton.click();
      });
    }
  }
}
</script>

<style>

</style>