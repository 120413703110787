<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--Details Produit--->
                    <div class="row">
                            <!-- Column -->
                            <div class="col-lg-12">
                                <div class="material-card card">
                                    <div class="alert alert-success text-success col-6 text-center mx-auto mt-3" v-if="message">{{message}}</div>
                                    <div class="card-body" v-if="info.sounom">
                                        <h3 class="card-title">{{info.sounom}}</h3>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3 col-sm-6 float-left">
                                                <div class="white-box text-center"> <img :src="`http://apiy.htvann.com/${info.cover_img}`" class="img-responsive img-fluid img-thumbnail"> </div>
                                            </div>
                                            <div class="col-lg-9 col-md-9 col-sm-6">
                                                <h4 class="box-title mt-5">Product description</h4>
                                                <p>{{info.description}}</p>
                                                <h2 class="mt-5">{{info.prix}} HTG</h2>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <h3 class="box-title mt-5">Info G&eacute;n&eacute;rale &nbsp; &nbsp; 
                                                <router-link :to="{ name: 'produit-editer', params: { id_produit: info.id } }" @click="redirectTo(`/produit-editer/${info.id}`);event.preventDefault();" class="text-inverse pr-2" title="Modifier Info"><i class="fa fa-edit"></i></router-link>
                                                </h3>
                                                <a href="#" class="btn btn-outline-light text-warning float-right" data-toggle="modal" :data-target="'#couleurss'" title="Valider">Ajouter Couleur pour ce produit</a>
                                                <!-- produit-editer -->
                                                <!--Debut Modal Ajouter Couleur-->
                                                                <div class="modal fade" :id="'couleurss'" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                    <div class="modal-dialog" role="document">
                                                                        <div class="modal-content">
                                                                        <div class="modal-header">
                                                                            <h5 class="modal-title" id="exampleModalLabel">Ajouter Couleur Produit</h5>
                                                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">&times;</span>
                                                                            </button>
                                                                        </div>
                                                                        <div class="modal-body">
                                                                            <p v-if="couleur.alertsuccess" class="text-success mt-1 text-center"> {{ couleur.alertsuccess }} </p>
                                                                            <form method="POST">
                                                                                <div class="col-sm-12">
						                                                            <div class="row">
                                                                                        <div class="col-sm-4 form-group">
                                                                                            <label for="pname">Couleur</label>
                                                                                            <input placeholder="Couleur" v-model="couleur.couleur" type="text" @keyup="viderAlert" class="form-control" required>
                                                                                        </div>
                                                                                        <div class="col-sm-4 form-group">
                                                                                            <label for="pname">Size</label>
                                                                                            <input placeholder="Size" v-model="couleur.size" type="text" @keyup="viderAlert" class="form-control" required>
                                                                                        </div>
                                                                                        <div class="col-sm-4 form">
                                                                                            <label for="pname">Quantit&eacute;</label>
                                                                                            <input placeholder="Quantit&eacute;" v-model="couleur.quantite" type="number" @keyup="viderAlert" class="form-control" required>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        <div class="modal-footer">
                                                                            <button type="button" :disabled="couleur.quantite === ''" @click="SaveCouleurWithQuantity" class="btn btn-primary float-left mx-auto">Ajouter</button>
                                                                            <button type="button" class="btn btn-secondary float-right mx-auto" data-dismiss="modal">Close</button>
                                                                            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                                                                        </div>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Fin Ajouter Couleur-->
                                                
                                                    <!-- <a href="/produits/editer/{{info.id}}" class="text-inverse pr-2" data-toggle="tooltip" title="Modifier Info"><i class="fa fa-edit"></i></a></h3> -->
                                                <!-- <a href="/produits/ajouter_images_produit/{{produit.id}}" class="btn btn-outline-light text-warning float-right">Ajouter image pour ce produit</a> -->
                                                
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td width="390">Nom</td>
                                                                <td>{{info.sounom}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Prix</td>
                                                                <td>{{info.prix}} HTG</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Disponible</td>
                                                                <td>{{ info.disponibilite === 1 ? 'Oui' : 'Non' }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Cat&eacute;gorie</td>
                                                                <td>{{info.categorie}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Article</td>
                                                                <td>{{info.article}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Poids</td>
                                                                <td>{{info.poid}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Volume</td>
                                                                <td>{{info.volume}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Stock</td>
                                                                <td>{{info.stock}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Boutique</td>
                                                                <!-- <td>{{info.boutique}}</td> -->
                                                                <td><router-link :to="{ name: 'boutique-details', params: { id_boutique: info.code_boutique } }" title="Details Boutique" @click="redirectTo(`/boutique-details/${info.code_boutique}`);event.preventDefault();">{{info.boutique}}</router-link></td>
                                                            </tr>
                                                            <tr>
                                                                <td>Enregistr&eacute;</td>
                                                                <td>{{formatDate(info.created)}}</td> 
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-md-12 col-sm-12" align="center">
                                                <!-- <a href="/produits/ajouter_images_produit/{{info.id}}" class="btn btn btn-outline-warning">Ajouter image pour ce produit</a> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="text-center p-3 text-uppercase font-weight-bold">Ce produit n'existe pas!</div>
                                </div>
                            </div>
                            <!-- Column -->
                        </div>
                <!--End Details Produit--->                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->

        <!-- <script src="/script.js"></script> -->
</template>

<script>
import axios from 'axios';
// import Master from '@/components/Master.vue';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:"DetailsProduit",
components:{
    HeaderTopBarVue,
    AsideMenuVue
},
data() {
    return {
      info:{
        id:'',
        sounom:'',
        prix:'',
        code_boutique:'',
        description:'',
        disponibilite:0,
        categorie:'',
        article : '',
        poid:0,
        volume:0,
        stock:'',
        boutique:'',
        created :'',
        cover_img:''
      },
      couleur:{
        couleur:'',
        size:'',
        quantite:'',
        alertsuccess:''
      },
      message:''
    };
  },
  created(){
    const code_produit = this.$route.params.id_produit;
    this.info.id = code_produit;
    this.message = this.$route.query.message;
    
    // console.log()
    axios.get(`${base_url}/produit?produit_id=${code_produit}`)
        .then(response=>{
            const produit = response.data[0];
                // console.log(response.data);
                // console.log(produit);
                // console.log(produit.id);
                // this.info.id = produit.id;
                this.info.sounom =produit.sounom;
                this.info.prix = produit.prix;
                this.info.disponibilite = produit.disponibilite;
                this.info.categorie = produit.categorie.categorie;
                this.info.article = produit.article.article;
                this.info.poid = produit.poid;
                this.info.volume = produit.volume;
                this.info.stock = produit.stock;
                this.info.boutique = produit.boutique.boutique;
                this.info.code_boutique = produit.boutique.id;
                this.info.created = produit.created;
                this.info.cover_img = produit.cover_img;
                this.info.description = produit.description;
            
        })
        .catch(err=>{
            console.log(err);
        });
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = ('0' + date.getDate()).slice(-2);
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        },1);
    },
    SaveCouleurWithQuantity(){
        console.log('SaveCouleurWithQuantity');
        // return false;
        const $datac = {
			produit_id:this.$route.params.id_produit,
            couleur:this.couleur.couleur,
            quantite:this.couleur.quantite
		};
        const $datas = {
			produit_id:this.$route.params.id_produit,
            size:this.couleur.size,
            quantite:this.couleur.quantite
		};

        if(this.couleur.size && this.couleur.couleur){
            this.couleur.couleur='';
            this.couleur.quantite='';
            this.couleur.size='';
            axios.all([
                axios.post(`${base_url}/add_couleur`,$datac),
                axios.post(`${base_url}/add_size`,$datas)
            ])
            .then(axios.spread((res1,res2)=>{
                console.log(`-------Felicitation!-------------`);
                console.log(res1);
                console.log(res2);
                if(res1.status==201 || res2.status==201){
                    // res.redirect(`/produits/details/${response.data.id}?sucess="Insertion Réussie!"`);
                    console.log('Ajouter Avec Success!');
                    this.couleur.alertsuccess="Couleur et Size Ajouter avec Succes!";
                }
                else{
                    // res.redirect(`/produits/register?error='Insertion Non Réussie!'&name=${req.body.pname}&description=${req.body.pdescription}&article=${req.body.particle}&categorie=${req.body.pcategorie}&scategorie=${req.body.pscategorie}&boutique=${req.body.pboutique}&geodepartement=${req.body.pgeodepartement}&commune=${req.body.pgeocommune}`);
                    console.log('Echec!!!');
                }
            }))
            .catch((err) => {
                console.log(err);
            });
        }
        else if(!this.couleur.size && this.couleur.couleur){
            axios.post(`${base_url}/add_couleur`,$datac)
            .then(response=>{
                this.couleur.couleur='';
                this.couleur.quantite='';
                this.couleur.size='';
                console.log(`-------Felicitation!-------------`);
                // console.log(response);
                // console.log(response.data);
                if(response.status==201){
                    // res.redirect(`/produits/details/${response.data.id}?sucess="Insertion Réussie!"`);
                    console.log('Ajouter Avec Success!');
                    this.couleur.alertsuccess="Couleur Ajouter avec Succes!";
                }
                else{
                    // res.redirect(`/produits/register?error='Insertion Non Réussie!'&name=${req.body.pname}&description=${req.body.pdescription}&article=${req.body.particle}&categorie=${req.body.pcategorie}&scategorie=${req.body.pscategorie}&boutique=${req.body.pboutique}&geodepartement=${req.body.pgeodepartement}&commune=${req.body.pgeocommune}`);
                    console.log('Echec!!!');
                }
            })
            .catch(err=>{
                console.log(err);
            });
        }
        else if(this.couleur.size && !this.couleur.couleur){
            axios.post(`${base_url}/add_size`,$datas)
            .then(response=>{
                this.couleur.couleur='';
                this.couleur.quantite='';
                this.couleur.size='';
                console.log(`-------Felicitation!-------------`);
                // console.log(response);
                // console.log(response.data);
                if(response.status==201){
                    // res.redirect(`/produits/details/${response.data.id}?sucess="Insertion Réussie!"`);
                    console.log('Ajouter Avec Success!');
                    this.couleur.alertsuccess="Size Ajouter avec Succes!";
                }
                else{
                    // res.redirect(`/produits/register?error='Insertion Non Réussie!'&name=${req.body.pname}&description=${req.body.pdescription}&article=${req.body.particle}&categorie=${req.body.pcategorie}&scategorie=${req.body.pscategorie}&boutique=${req.body.pboutique}&geodepartement=${req.body.pgeodepartement}&commune=${req.body.pgeocommune}`);
                    console.log('Echec!!!');
                }
            })
            .catch(err=>{
                console.log(err);
            });
        }
         
    },
    viderAlert(){
        this.couleur.alertsuccess="";
    }
  }
}
</script>

<style>

</style>