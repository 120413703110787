<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                      <!--Contenu ALLUser-->
                      <div class="">
                            <!-- Column -->
                                <section id="tabs" class="project-tab">
                                    <div class="">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="material-card card">
                                                <div class="card-body col-auto">
                                                <nav>
                                                    <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                                                        <a class="nav-item nav-link active" id="nav-topsearch-tab" data-toggle="tab" href="#nav-topsearch" role="tab" aria-controls="nav-topsearch" aria-selected="true">Top des mots recherch&eacute;s <span class="badge badge-inverse badge-pill ml-auto mr-3 font-medium px-2 py-1">{{topwords.length}}</span></a>
                                                        <a class="nav-item nav-link" id="nav-search-tab" data-toggle="tab" href="#nav-search" role="tab" aria-controls="nav-search" aria-selected="false">Liste des mots recherch&eacute;s <span class="badge badge-inverse badge-pill ml-auto mr-3 font-medium px-2 py-1">{{words.length}}</span></a>
                                                    </div>
                                                </nav>
                                                <div class="tab-content" id="nav-tabContent">
                                                    <div class="tab-pane fade show active" id="nav-topsearch" role="tabpanel" aria-labelledby="nav-topsearch-tab">
                                                        <table class="table datatable product-overview w-75 text-center mx-auto" cellspacing="0"  v-if="topwords.length>0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Mots</th>
                                                                    <th>Apparition</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                    <tr v-for="topword in topwords" :key="topword.element">
                                                                        <td>#{{topword.element}}</td>
                                                                        <td>{{topword.total_apparitions}}</td>
                                                                    </tr>                                
                                                            </tbody>
                                                        </table>
                                                        <p v-else>Loading...</p>                                                 
                                                        
                                                    </div>
                                                    <div class="tab-pane fade" id="nav-search" role="tabpanel" aria-labelledby="nav-search-tab">
                                                        <table class="table product-overview w-75 text-center mx-auto" cellspacing="0" v-if="words.length>0">
                                                            <thead>
                                                                <tr>
                                                                    <!-- <th>Code</th> -->
                                                                    <th>Mots</th>
                                                                    <th>Date</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                    <tr v-for="word in words" :key="word.id">
                                                                        <!-- <td>#{{word.id}}</td> -->
                                                                        <td>{{word.element}}</td>
                                                                        <td>{{ formatDate(word.created)}}</td>
                                                                    </tr>                                
                                                            </tbody>
                                                        </table>
                                                        <p v-else>Loading...</p>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            <!-- Column -->
                        </div>


                      <!--End Contenu ALLUser-->
                            
                        
                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->

</template>

<script>
import axios from 'axios';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:'AllSearch',
components: {
    HeaderTopBarVue,
    AsideMenuVue
  },
  data(){
    return{
        words:[],
        topwords:[]
    }
  },
  async created(){
            axios.all([
                axios.get(`${base_url}/keywords_search`),
                axios.get(`${base_url}/top_search`)
            ])
            .then(axios.spread((res1,res2)=>{
                this.words=res1.data;
                this.topwords=res2.data;
                // console.log(this.words);
                // console.log(this.topwords);
            }))
            .catch((error)=>{
                alert('Une erreur est survenue!');
                console.error(error);
            })
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    reloaded() {
        const route = this.$route.path;
        // console.log(route+'ok');
        this.$router.push(route);
        setTimeout(function() {
          location.reload();
        }, 1);
    }
  }
}
</script>

<style>
.project-tab {
    padding: 10%;
    margin-top: -8%;
}
.project-tab #tabs{
    background: #007b5e;
    color: #eee;
}
.project-tab #tabs h6.section-title{
    color: #eee;
}
.project-tab #tabs .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #0062cc;
    background-color: transparent;
    border-color: transparent transparent #f3f3f3;
    border-bottom: 3px solid !important;
    font-size: 16px;
    font-weight: bold;
}
.project-tab .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #0062cc;
    font-size: 16px;
    font-weight: 600;
}
.project-tab .nav-link:hover {
    border: none;
}
.project-tab thead{
    background: #f3f3f3;
    color: #333;
}
.project-tab a{
    text-decoration: none;
    color: #333;
    font-weight: 600;
}
</style>