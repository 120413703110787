import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    usercryp:null
  },
  getters: {
    currentUser(state) {
      return state.user;
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
      // sessionStorage.setItem('user', JSON.stringify(user));
    },
    setUserCryp(state,usercryp) {
      state.usercryp = usercryp;
      localStorage.setItem('usercryp', JSON.stringify(usercryp));
      // sessionStorage.setItem('user', JSON.stringify(user));
    },
    removeUser(state) {
      state.user = null;
      localStorage.removeItem('user');
      // sessionStorage.removeItem('user');
    }
  },
  actions: {
  },
  modules: {
  }
})
