<template>
  <!-- ============================================================== -->
        <!-- Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
        <aside class="material left-sidebar">
            <!-- Sidebar scroll-->
            <div class="scroll-sidebar">
                <!-- Sidebar navigation-->
                <nav class="sidebar-nav">
                    <ul id="sidebarnav">
                        <li class="sidebar-item">
                            <router-link class="sidebar-link" :to="{name:'all-search'}" @click="redirectTo('/all-search');event.preventDefault();" aria-expanded="false">
                                <i class="fa fa-tag"></i>
                                <span class="hide-menu">Mots Cl&eacute;s recherch&eacute;s</span>
                            </router-link>
                        </li>
                        <li class="sidebar-item">
                            <router-link class="sidebar-link" :to="{name:'all-panier'}" @click="redirectTo('/all-panier');event.preventDefault();" aria-expanded="false">
                                <i class="fa fa-briefcase"></i>
                                <span class="hide-menu">Contenu Paniers</span>
                            </router-link>
                        </li>
                        <li class="sidebar-item">
                            <a class="sidebar-link has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                <i class="fa fa-user-circle"></i>
                                <span class="hide-menu">Clients</span> 
                            </a>
                            <ul aria-expanded="false" class="collapse  first-level">
                                <li class="sidebar-item">
                                    <router-link :to="{name:'user-register'}" class="sidebar-link" @click="redirectTo('/user-register');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">Ajouter Clients</span>
                                    </router-link>                                    
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name:'all-user'}" class="sidebar-link" @click="redirectTo('/all-user');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu" >Lister Clients</span>
                                    </router-link>
                                </li>                                
                            </ul>
                        </li>
                        <div class="devider"></div>
                        <li class="sidebar-item">
                            <a class="sidebar-link has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                <i class="fa fa-store"></i>
                                <span class="hide-menu">Boutiques</span>                          
                            </a>
                            <ul aria-expanded="false" class="collapse first-level">
                                <li class="sidebar-item">
                                    <a href="#" class="sidebar-link" @click="redirectTo('/boutique-register');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">Ajouter Boutiques</span>
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name:'all-boutique'}" class="sidebar-link" @click="redirectTo('/all-boutique');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">Lister Boutiques</span>
                                    </router-link>
                                </li>
                                
                            </ul>
                        </li>
                        <div class="devider"></div>
                        <li class="sidebar-item">
                            <a class="sidebar-link has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                <i class="fa fa-shopping-cart"></i>
                                <span class="hide-menu">Produits</span>                          
                            </a>
                            <ul aria-expanded="false" class="collapse first-level">
                                <li class="sidebar-item">
                                    <router-link :to="{name:'produit-register'}" class="sidebar-link" @click="redirectTo('/produit-register');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">Ajouter Produits</span>
                                    </router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name:'all-produit'}" class="sidebar-link" @click="redirectTo('/all-produit');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">Lister Produits</span>
                                    </router-link>
                                </li>
                                
                            </ul>
                        </li>
                        <div class="devider"></div>
                        <li class="sidebar-item">
                            <a class="sidebar-link has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                <i class="fa fa-file-alt"></i>
                                <span class="hide-menu">Commandes</span>                         
                            </a>
                            <ul aria-expanded="false" class="collapse first-level">
                                <li class="sidebar-item">
                                    <router-link :to="{name:'commande-attente'}" class="sidebar-link" @click="redirectTo('/commande-attente');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">En attente</span>
                                    </router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name:'commande-reussie'}" class="sidebar-link" @click="redirectTo('/commande-reussie');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">Réussie</span>
                                    </router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name:'commande-echouee'}" class="sidebar-link" @click="redirectTo('/commande-echouee');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">&Eacute;chouée</span>
                                    </router-link>
                                </li>                                
                                <li class="sidebar-item">
                                    <router-link :to="{name:'all-decaissement'}" class="sidebar-link" @click="redirectTo('/all-decaissement');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">Décaissement</span>
                                    </router-link>
                                </li>                                
                            </ul>
                        </li>
                        <div class="devider"></div>
                        <li class="sidebar-item">
                            <a class="sidebar-link has-arrow waves-effect waves-dark" href="#" aria-expanded="false">
                                <i class="fa fa-truck"></i>
                                <span class="hide-menu">Livraison</span>                         
                            </a>
                            <ul aria-expanded="false" class="collapse first-level">
                                <!-- <li class="sidebar-item">
                                    <router-link to="/commande-en-attente" class="sidebar-link">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">En Attente</span>
                                    </router-link>
                                </li> -->
                                <li class="sidebar-item">
                                    <router-link :to="{name:'commande-emballee'}" class="sidebar-link" @click="redirectTo('/commande-emballee');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">À emballer</span>
                                    </router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name:'commande-expediee'}" class="sidebar-link" @click="redirectTo('/commande-expediee');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">À expédier</span>
                                    </router-link>
                                </li>
                                <li class="sidebar-item">
                                    <router-link :to="{name:'commande-livree'}" class="sidebar-link" @click="redirectTo('/commande-livree');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">À livrer</span>
                                    </router-link>
                                </li>
                                
                            </ul>
                        </li>
                        <!-- <div class="devider"></div>
                        <li class="sidebar-item">
                            <a class="sidebar-link has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false">
                                <i class="fa fa-user-friends"></i>
                                <span class="hide-menu">Clients</span>
                            </a>
                            <ul aria-expanded="false" class="collapse  first-level">
                                <li class="sidebar-item">
                                    <a href="/customers/register/" class="sidebar-link">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">Ajouter Client </span>
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="/customers/" class="sidebar-link">
                                        <i class="fa fa-list" aria-hidden="true"></i>
                                        <span class="hide-menu">Lister Client </span>
                                    </a>
                                </li>                                
                            </ul>
                        </li> -->
                        <div class="devider"></div>
                        <li class="sidebar-item">
                            <a class="sidebar-link has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false">
                                <i class="fa fa-dollar-sign"></i>
                                <span class="hide-menu">Gestion Caisse</span>
                            </a>
                            <ul aria-expanded="false" class="collapse  first-level">
                                <li class="sidebar-item">
                                    <router-link :to="{name:'all-transaction'}" class="sidebar-link" @click="redirectTo('/all-transaction');event.preventDefault();">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">Transactions</span>
                                    </router-link>
                                </li>
                                <li class="sidebar-item">
                                    <a href="sidebar-type-iconsidebar.html" class="sidebar-link">
                                        <i class="mdi mdi-view-parallel"></i>
                                        <span class="hide-menu">Rapport de Caisse </span>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <div class="devider"></div>
                        <li class="sidebar-item">
                            <a class="sidebar-link has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false">
                                <i class="fa fa-user"></i>
                                <span class="hide-menu">Gestion Utilisateurs</span>
                            </a>
                            <ul aria-expanded="false" class="collapse  first-level">
                                <li class="sidebar-item">
                                    <a href="#" class="sidebar-link">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">Ajouter</span>
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="#" class="sidebar-link">
                                        <i class="fa fa-list" aria-hidden="true"></i>
                                        <span class="hide-menu">Lister</span>
                                    </a>
                                </li>                                
                            </ul>
                        </li>
                        <div class="devider"></div>
                        <li class="sidebar-item">
                            <a class="sidebar-link has-arrow waves-effect waves-dark" href="javascript:void(0)" aria-expanded="false">
                                <i class="fa fa-users"></i>
                                <span class="hide-menu">Gestion Groupes</span>
                            </a>
                            <ul aria-expanded="false" class="collapse  first-level">
                                <li class="sidebar-item">
                                    <a href="#" class="sidebar-link">
                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                        <span class="hide-menu">Ajouter</span>
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a href="#" class="sidebar-link">
                                        <i class="fa fa-list" aria-hidden="true"></i>
                                        <span class="hide-menu">Lister</span>
                                    </a>
                                </li>                                
                            </ul>
                        </li>
                        <div class="devider"></div>
                        <li class="sidebar-item">
                            <a class="sidebar-link waves-effect waves-dark sidebar-link" href="#" aria-expanded="false">
                                <i class="mdi mdi-adjust text-danger"></i>
                                <span class="hide-menu">Documentation</span>
                            </a>
                        </li>
                        <li class="sidebar-item">
                            <a class="sidebar-link waves-effect waves-dark sidebar-link" href="#" aria-expanded="false">
                                <i class="mdi mdi-adjust text-success"></i>
                                <span class="hide-menu">FAQs</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <!-- End Sidebar navigation -->
            </div>
            <!-- End Sidebar scroll-->
        </aside>
        <!-- ============================================================== -->
        <!-- End Left Sidebar - style you can find in sidebar.scss  -->
        <!-- ============================================================== -->
</template>

<script>
export default {
name:'AsideMenuVue',
methods:{
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        },1);
    }
}
}
</script>

<style>

</style>