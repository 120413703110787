<template>
  <HeaderTopBarVue/>
  <AsideMenuVue/>
   <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content container"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                      <!--Contenu DashBoard-->
                      <div class="row">
                      <div class="col-md-12">
                      <!-- page Content -->

                        <div class="container" role="main">
                            <!-- top tiles -->
                            <div class="row tile_count" align="center">
                                    <div class="col-md-2 col-sm-4 col-xs-6 tile_stats_count">
                                      <a href="/clients">
                                        <span class="count_top"><i class="fa fa-user"></i> Clients</span>
                                        <div class="count tay">100</div>

                                      </a><!-- <span class="count_bottom"><i class="green">4% </i></span> -->
                                    </div>
                                    <div class="col-md-2 col-sm-4 col-xs-6 tile_stats_count">
                                      <span class="count_top"><i class="fa fa-money"></i> Ventes</span>
                                      <div class="count tay">100</div>
                                      <span class="count_bottom">US</span>
                                    </div>
                                    <div class="col-md-2 col-sm-4 col-xs-6 tile_stats_count">
                                      <span class="count_top"><i class="fa fa-money"></i> Balances</span>
                                      <div class="count tay">100</div>
                                      <span class="count_bottom">US</span>
                                      <!-- <span class="count_bottom"><i class="green"><i class="fa fa-sort-asc"></i>34% </i> From last Week</span> -->
                                    </div>
                                    <div class="col-md-2 col-sm-4 col-xs-6 tile_stats_count">
                                      <span class="count_top"><i class="fa fa-money"></i> Taxes</span>
                                      <div class="count tay">30</div>
                                      <span class="count_bottom">US</span>
                                      <!-- <span class="count_bottom"><i class="red"><i class="fa fa-sort-desc"></i>12% </i> From last Week</span> -->
                                    </div>
                                    <div class="col-md-2 col-sm-4 col-xs-6 tile_stats_count">
                                      <span class="count_top"><i class="fa fa-money"></i> Limite Cr&eacute;dit</span>
                                      <div class="count">100</div>
                                      <span class="count_bottom">US</span>
                                      <!-- <span class="count_bottom"><i class="green"><i class="fa fa-sort-asc"></i>34% </i> From last Week</span> -->
                                    </div>
                                  </div>
                                  <hr>
                                  <!-- /top tiles -->
                        </div>
                      </div>   
                  </div>

                      <!--End Contenu DashBoard-->
                            
                        
                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
</template>

<script>
// import 'jquery/dist/jquery.min.js';
// import 'popper.js/dist/popper.min.js';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;

export default {
name:'Dashboard',
data(){
  return {
    user:''
  }
},
components: {
    HeaderTopBarVue,
    AsideMenuVue
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('user')||'null');
    // console.log(this.user);
  },
  computed: {
    user() {
      return this.$store.state.user;
    }
  },
  methods:{
    
    decryptObject(encryptedData, encryptionKey) {
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, key).toString(CryptoJS.enc.Utf8);
        const deserializedObj = JSON.parse(decryptedData);
        return deserializedObj;
    }
  }
}
</script>

<style>

</style>