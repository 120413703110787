<template>
  <HeaderTopBarVue/>
  <AsideMenuVue/>
  <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--AllProduit--->
                <div class="row">
                            <!-- Column -->
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="material-card card">
                                        <div class="card-body">
                                            <h2 style="margin-top:0px;">Les commandes à emballer <span class="badge badge-inverse badge-pill ml-auto mr-3 font-medium px-2 py-1">{{orders.length}}</span></h2>
                                        <!-- <hr class="mt-3 mb-4"> -->
                                            <div class="table-responsive">
                                                <table class="table product-overview" id="zero_config" v-if="orders.length>0">
                                                    <thead> 
                                                        <tr>
                                                            <th>ID</th>
                                                            <th>Utilisateur</th>
                                                            <th>T&eacute;l&eacute;phone</th>
                                                            <th>Adresse</th>
                                                            <th>Commune</th>
                                                            <th>Departement</th>
                                                            <th>Date</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                            <tr v-for="order in orders" :key="order.commande_id">
                                                                <td>#{{order.commande_id}}</td>
                                                                <td> {{order.nom_user}} {{order.prenom_user}} </td>
                                                                <td>{{order.phone_user}}</td>
                                                                <td>{{order.rue_user_adresse}}, {{order.zone_user_adresse}}</td>
                                                                <td>{{order.commune}}</td>
                                                                <td>{{order.geodepartement}}</td>
                                                                <td>{{formatDate(order.date_creation)}}</td>
                                                                <td>
                                                                    <!-- <router-link :to="{ name: 'produit-details', params: { id_produit: produit.id } }" class="text-inverse pr-2" data-toggle="tooltip" title="Details"><i class="fa fa-eye"></i></router-link> -->
                                                                    <router-link :to="{ name: 'commande-details', params: { id_order: order.commande_id} }" @click="redirectTo(`/commande-details/${order.commande_id}`);event.preventDefault();" class="text-inverse pr-2" data-toggle="tooltip" title="Details"><i class="fa fa-eye"></i></router-link>
                                                                    <router-link :to="{ name: 'list-ele-emballer', params: { id_order: order.commande_id} }" @click="redirectTo(`/list-ele-emballer/${order.commande_id}`);event.preventDefault();" class="text-inverse" title="Emballer" data-toggle="tooltip"><i class="fa fa-gift"></i></router-link>
                                                                </td>
                                                            </tr>                                   
                                                        
                                                    </tbody>
                                                </table>
                                                <p v-else>Aucune Commande à emballer.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            <!-- Column -->
                        </div>

                <!--End AllProduit--->
                            
                        
                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
</template>

<script>
import axios from 'axios';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'jquery/dist/jquery.min.js';
// import 'popper.js/dist/popper.min.js';
// import 'bootstrap/dist/js/bootstrap.min.js';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:"CommandeEmballee",
components: {
    HeaderTopBarVue,
    AsideMenuVue
  },
  data() {
    return {
      orders: []
    };
  },
  async created(){
       axios.get(`${base_url}/commande?step_status=0`)
            .then((response)=>{
                // console.log(response.data);
                this.orders=response.data;
            })
            .catch((error)=>{
                alert('Une erreur est survenue!');
                console.error(error);
            })
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        },1);
    }
  }
}
</script>

<style>

</style>