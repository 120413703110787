<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--Details Produit--->
                    <div class="row">
                            <!-- Column -->
                            <div class="col-lg-12">
                                <div class="material-card card">
                                    <div class="card-body">
                                        <h3 class="card-title">{{info.boutique}}</h3>
                                        <div class="row float-right mr-5">
                                            <router-link :to="{ name: 'produit-boutique', params: { id_boutique: info.id } }" title="Produits" @click="redirectTo(`/produit-boutique/${info.id}`);event.preventDefault();" class="btn btn-info p-3 text-uppercase font-weight-bold">Produits</router-link>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3 col-sm-6 float-left">
                                                <div class="white-box text-center"> <img :src="`http://apiy.htvann.com/${info.cover_img}`" class="img-responsive img-fluid img-thumbnail"> </div>
                                            </div>
                                            <div class="col-lg-9 col-md-9 col-sm-6">
                                                <h4 class="box-title mt-5">&Agrave; Propos</h4>
                                                <p>{{info.propos}}</p>
                                                <h2 class="mt-5">{{info.slogan}}</h2>
                                            </div>
                                            <div class="col-lg-12 col-md-12 col-sm-12">
                                                <h3 class="box-title mt-5">Info G&eacute;n&eacute;rale &nbsp; &nbsp; 
                                                <a href="#" title="Modifier Info" @click="redirectTo(`/boutique-editer/${info.id}`);event.preventDefault();"><i class="fa fa-edit"></i></a>
                                                </h3>
                                                <!-- produit-editer -->
                                                
                                                    <!-- <a href="/produits/editer/{{info.id}}" class="text-inverse pr-2" data-toggle="tooltip" title="Modifier Info"><i class="fa fa-edit"></i></a></h3> -->
                                                <!-- <a href="/produits/ajouter_images_produit/{{produit.id}}" class="btn btn-outline-light text-warning float-right">Ajouter image pour ce produit</a> -->
                                                
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <tbody>
                                                            <tr>
                                                                <td width="390">Nom</td>
                                                                <td>{{info.boutique}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Slogan</td>
                                                                <td>{{info.slogan}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Status</td>
                                                                <td>{{ info.role_user === 'VENDEUR' ? 'Valid&eacute;' : 'Non-valid&eacute;' }}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Proprietaire</td>
                                                                <td>{{info.proprietaire}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Telephone</td>
                                                                <td>{{info.telephone}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Email</td>
                                                                <td>{{info.email}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Adresse</td>
                                                                <td>{{ info.adresse ? info.adresse : ''}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Role</td>
                                                                <td>{{info.role_user}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Vente</td>
                                                                <td class="font-weight-bold">{{info.vente}} HTG</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Commission-HTvann</td>
                                                                <td class="font-weight-bold">{{info.comHtv}} HTG</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Disponiblité</td>
                                                                <td class="font-weight-bold">{{info.montant_dispo}} HTG</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Enregistr&eacute;</td>
                                                                <td>{{formatDate(info.created)}}</td> 
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <a href="#" @click="redirectTo(`/all-boutique`);event.preventDefault();" class="btn btn-info">Tous les Boutiques</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Column -->
                        </div>
                <!--End Details Produit--->                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->

        <!-- <script src="/script.js"></script> -->
</template>

<script>
import axios from 'axios';
// import Master from '@/components/Master.vue';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:"DetailsBoutique",
components:{
    HeaderTopBarVue,
    AsideMenuVue
},
data() {
    return {
      info:{
        montant_dispo:0,
        vente:0,
        comHtv:0,
        id:'',
        boutique:'',
        propos:'',
        slogan:'',
        proprietaire:'',
        role_user:'',
        created:'',
        telephone:'',
        email:'',
        adresse:'',
      }
    };
  },
  created(){
    const code_boutique = this.$route.params.id_boutique; 
    this.info.id = code_boutique;
    console.log(code_boutique);
    this.getMontantDispo(parseInt(code_boutique));
    axios.get(`${base_url}/boutique?boutique_id=${code_boutique}`)
        .then(response=>{
            const boutik = response.data[0];
            if(boutik){
                // console.log(boutik);
                // console.log(produit.id);
                this.info.id = boutik.code_boutique;
                this.info.boutique =boutik.boutique_name;
                this.info.propos = boutik.propos_boutique;
                this.info.slogan = boutik.boutique_slogan;
                this.info.proprietaire = `${boutik.nom_user} ${boutik.prenom_user}`;
                this.info.role_user=boutik.role_user;
                this.info.created = boutik.date_creation;
                this.info.telephone = boutik.phone_user;
                this.info.adresse=(boutik.rue_user_adresse || boutik.zone_user_adresse)?`${boutik.rue_user_adresse}, ${boutik.zone_user_adresse}`:'';
                this.info.email = boutik.email_user;
                // this.info.categorie = produit.categorie.categorie;
                // this.info.article = produit.article.article;
                // this.info.poid = produit.poid;
                // this.info.volume = produit.volume;
                // this.info.stock = produit.stock;
                // this.info.boutique = produit.boutique.boutique;
                // this.info.created = produit.created;
                // this.info.cover_img = produit.cover_img;
                // this.info.description = produit.description;
            }else{
                this.$router.push('/all-produit');
            }
        })
        .catch(err=>{
            console.log(err);
        });
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        }, 1);
    },
    getMontantDispo(code){
        axios.get(`${base_url}/transactions_success`)
            .then((response)=>{
                if(response.data){
                    // console.log(response.data);
                    // return false;
                    let filterTri=response.data.filter((element)=>element.code_boutique===code && element.status==="successful");
                    // let montant_achat = filterTri.filter((element)=>element.typ==='Achat').reduce((total,element)=>total+element.montant,0);
                    let montant_decaisser = filterTri.filter((element)=>element.typ!=='Achat').reduce((total,element)=>total+element.montant,0);
                    this.info.vente = filterTri.reduce((total,element)=>total+element.prixproduitvendu,0);
                    this.info.comHtv = this.info.vente * 0.1;
                    this.info.montant_dispo = this.couperNombreDeuxDecimales((this.info.vente-this.info.comHtv)-montant_decaisser);
                    // console.log(response.data);
                    // console.log(filterTri);
                    // console.log(montant_achat);
                    // console.log(this.info.vente);
                    // console.log(montant_decaisser);
                    // console.log(this.info.montant_dispo);
                }
            })
            .catch((error)=>{
                alert('Une erreur est survenue!');
                console.error(error);
            })
        
    },
    couperNombreDeuxDecimales(nombre) {
        // Vérifier si le nombre est fini et non NaN
        if (!Number.isFinite(nombre)) {
            throw new Error("Le nombre fourni n'est pas valide.");
        }

        // Vérifier si le nombre est décimal
        if (!Number.isInteger(nombre)) {
            // Utilise toFixed pour obtenir le nombre avec deux décimales et le transformer en chaîne
            const nombreAvecDeuxDecimales = nombre.toFixed(2);

            // Utilise parseFloat pour reconvertir la chaîne en nombre avec deux décimales
            return parseFloat(nombreAvecDeuxDecimales);
        }

        // Si le nombre est un nombre entier, pas besoin de couper les décimales, retourner simplement le nombre
        return nombre;
}
  },
  computed:{
    
  }
}
</script>

<style>

</style>