<template>
    <HeaderTopBarVue/>
    <AsideMenuVue/>
    <!-- Page wrapper  -->
        <!-- ============================================================== -->
        <div class="page-wrapper">
          <!-- Container fluid  -->
            <!-- ============================================================== -->
            <div class="page-content"> 
                <!-- ============================================================== -->
                <!-- Start Page Content -->
                <!-- ============================================================== -->
                    <!-- Load data page -->
                     <!-- Injected view here-->
                <!--Editer Produit--->
                    <div class="row">
                    <!-- Column -->
                    <div class="col-lg-12">
                        <div class="material-card card">
                            <div class="card-body">
                                <form>
                                    <div class="form-body">
                                        <h5 class="card-title">Modifier Information Boutique</h5>
                                        <hr>
                                        <!--/row-->
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="control-label">Nom</label>
                                                    <input type="text" id="first" class="form-control" v-model="info.boutique" readonly> 
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="control-label">Propri&eacute;taire</label>
                                                    <input type="text" id="" class="form-control" v-model="info.proprietaire" readonly> 
                                                </div>
                                            </div>
                                        </div>
                                        <!--/row-->
                                        <!--/row-->
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="control-label">Email</label>
                                                    <input type="text" id="" class="form-control" v-model="info.email" readonly> 
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="control-label">T&eacute;l&eacute;phone</label>
                                                    <input type="text" id="" class="form-control" v-model="info.telephone" readonly> 
                                                </div>
                                            </div>
                                        </div>
                                        <!--/row-->
                                        <!--/row-->
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="pcategorie" class="control-label">R&ocirc;le</label>
                                                    <input type="text" id="" class="form-control" v-model="info.role_user" readonly> 
                                                </div>
                                            </div>
                                
                                        </div>
                                        <h5 class="card-title mt-5">&Agrave; Propos</h5>
                                        <div class="row">
                                            <div class="col-md-12 ">
                                                <div class="form-group">
                                                    <textarea class="form-control" rows="4" v-model="info.propos" ></textarea>
                                                </div>
                                            </div>
                                        </div>                                          
                                        <div class="row" v-if="info.role_user!='VENDEUR'">
                                            <div class="col-md-6">
                                                    <button type="button" class="btn btn-success" @click="valider_boutique(info.utilisateur)">Valider Boutique</button>                                                
                                            </div>
                                        </div>                                  
                                        <hr> 
                                        </div>
                                    <div class="form-actions mt-5">
                                        <button type="submit" class="btn btn-success"> <i class="fa fa-check"></i> Save</button>
                                        <router-link :to="{ name: 'boutique-details', params: { id_produit: info.id } }" @click="redirectTo(`/boutique-details/${info.id}`);event.preventDefault();" class="text-inverse pr-2 btn btn-warning float-right text-light cancel" title="Modifier Info"><i class="fa fa-close" aria-hidden="true"></i> &nbsp;Cancel</router-link>
                                        <!-- <a type="button" href="/produits/details/{{produit.id}}" class="btn btn-dark">Cancel</a> -->
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <!-- Column -->
                </div>
                <!--End Editer Produit--->                        
            <!-- injected view here -->
                    <!-- Load data page -->        
                <!-- ============================================================== -->
                <!-- End PAge Content -->
                <!-- ============================================================== -->
                <!-- ============================================================== -->
                <!-- Right sidebar -->
                <!-- ============================================================== -->
                <!-- .right-sidebar -->
                <!-- ============================================================== -->
                <!-- End Right sidebar -->
                <!-- ============================================================== -->
            </div>
            <!-- ============================================================== -->
            <!-- End Container fluid  -->
            <!-- ============================================================== -->
            <!-- ============================================================== -->
            <!-- footer -->
            <!-- ============================================================== -->
            <footer class="footer text-center">
                Zode © 2023 Tous droits reservés.
            </footer>
            <!-- ============================================================== -->
            <!-- End footer -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Page wrapper  -->
        <!-- ============================================================== -->
</template>

<script>
import axios from 'axios';
import HeaderTopBarVue from '@/components/HeaderTopBar.vue';
import AsideMenuVue from '@/components/AsideMenu.vue';
import {systemInfo} from '../services/systemInfo'
const base_url = systemInfo.base_url;
export default {
name:"EditerBoutique",
components:{
    HeaderTopBarVue,
    AsideMenuVue
},
data() {
    return {
      info:{
        id:'',
        boutique:'',
        propos:'',
        slogan:'',
        proprietaire:'',
        role_user:'',
        created:'',
        telephone:'',
        email:'',
        adresse:'',
        utilisateur:''
      }
    };
  },
  created(){
    const code_boutique = this.$route.params.id_boutique;
    this.info.id = code_boutique;
    console.log(code_boutique);
    // console.log(code_produit);
axios.get(`${base_url}/boutique?boutique_id=${code_boutique}`)
        .then(response=>{
        const boutik = response.data[0];
            if(boutik){
                console.log(boutik);
                this.info.id = boutik.code_boutique;
                this.info.boutique =boutik.boutique_name;
                this.info.propos = boutik.propos_boutique;
                this.info.slogan = boutik.boutique_slogan;
                this.info.proprietaire = `${boutik.nom_user} ${boutik.prenom_user}`;
                this.info.role_user=boutik.role_user;
                this.info.created = boutik.date_creation;
                this.info.telephone = boutik.phone_user;
                this.info.adresse=(boutik.rue_user_adresse || boutik.zone_user_adresse)?`${boutik.rue_user_adresse}, ${boutik.zone_user_adresse}`:'';
                this.info.email = boutik.email_user;
                this.info.utilisateur = boutik.utilisateur;
            }else{
                this.$router.push('/all-produit');
            }
        
		// console.log(res3.data);
	})
        .catch(err=>{
            console.log(err);
        });
  },
  methods:{
    formatDate(dateString) {
      const date = new Date(dateString)
      const day = ('0' + date.getDate()).slice(-2)
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    redirectTo(path) {
        this.$router.push(path);
        setTimeout(function() {
          location.reload();
        }, 1000);
    },
    valider_boutique(userId){
        const infodata = {
			user:userId
		}
        // console.log(userId);
        axios.put(`${base_url}/validerBoutique`,infodata)
        .then(response=>{
            console.log(`-------Felicitation!-------------`);
            if(response.status==200){
                console.log(response.data)
                this.$router.go(0);
                    // res.redirect(`/produits/details/${response.data.product.id}?sucess="Update Réussie!"`);
            }
            else{
                this.$router.push({ name: 'all-produit', params: { id_produit: this.info.id } });
                //res.redirect(`/produits/register?error='Insertion Non Réussie!'&name=${req.body.pname}&description=${req.body.pdescription}&article=${req.body.particle}&categorie=${req.body.pcategorie}&scategorie=${req.body.pscategorie}&boutique=${req.body.pboutique}&geodepartement=${req.body.pgeodepartement}&commune=${req.body.pgeocommune}`);
            }
        })
        .catch(err=>{
            console.log(err);
        });

    }
  }
}
</script>

<style>
.fa-close{
    color:red;
}
</style>